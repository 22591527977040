const sanitizeObject = (obj, fields) => {
    let sanitized = {};
    fields.forEach(field => {
        if (field === 'idt') {
            let [value] = obj[field].match(/\d+/) || [];
            value = value && value.replace(/^0+/, '');
            return sanitized[field] = value;
        }
        sanitized[field] = obj[field]
    });
    return sanitized
};

export default sanitizeObject;