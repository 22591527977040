<template>
    <v-card flat class="text-center custom-shadow" @click="$emit('click', processName, selected ? null : activeFilter)">
        <div class="process-card">
            <v-card-title v-text="processTitle" class="justify-center pb-1 pt-1" :class="{selected: selected}"/>
            <v-row dense>
                <v-col cols="12" class="inprogress mt-2">
                    {{ $t('dashboard.ongoing') }}
                    <span v-text="processStat.ongoing"/>
                </v-col>
                <v-col class="mb-2">
                    <span v-text="'(' + processStat.wait_for_cbs_update"/>
                    {{ $t('dashboard.wait_for_cbs_update') }})
                </v-col>
            </v-row>
            <v-divider class="mr-2 ml-2 mb-2"></v-divider>
            <div class="failed-completed-row">

                <div class="col-6 black--text elevate" @click.stop="onSubFilterClick('completed')">
                    <v-icon large :class="completedFilter ? 'green--text' : 'blue-grey--text'">fa-thumbs-up</v-icon>
                    <div class="font-weight-bold text-h6" v-text="processStat.completed"/>
                </div>

                <v-divider vertical class=" mb-3 "></v-divider>

                <div class="col-6 black--text elevate" @click.stop="onSubFilterClick('failed')">
                    <v-icon large :class="failedFilter ? 'red--text' : 'blue-grey--text'">fa-times</v-icon>
                    <div class="font-weight-bold text-h6" v-text="processStat.failed"/>
                </div>

            </div>
        </div>

    </v-card>
</template>

<script>
export default {
    name: 'processCardFilter',
    props: ['processStat', 'processName', 'processTitle', 'hover', 'selected'],
    data() {
        return {
            completedFilter: false,
            failedFilter: false,
        }
    },
    mounted() {

        if (this.$route.query.process !== this.processName) return;

        const queryFilter = this.$route.query.filter;

        if (queryFilter !== 'ongoing') {
            this[queryFilter + 'Filter'] = true;
        }

    },
    computed: {
        activeFilter() {
            if (this.completedFilter) {
                return 'completed';
            }
            if (this.failedFilter) {
                return 'failed';
            }
            return 'ongoing';
        }
    },
    methods: {
        onSubFilterClick(filter) {

            switch (filter) {
                case 'completed':
                    this.completedFilter = !this.completedFilter;
                    this.failedFilter = false;
                    break;
                case 'failed':
                    this.failedFilter = !this.failedFilter;
                    this.completedFilter = false;
            }

            if (this.activeFilter === 'ongoing') {
                return this.$emit('click', this.processName, 'ongoing');
            }

            this.$emit('click', this.processName, filter);
        }
    },
}
</script>

<style lang="scss">

.elevate:hover {
    box-shadow: inset 1px 40px 38px -35px rgba(0, 0, 0, 0.38);
    border-radius: 25% 25%;
}

.custom-shadow {
    z-index: 0;
    position: relative;
    background-color: #FFFFFF;
    width: 180px;

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        box-shadow: 0 0 37px -13px rgba(0, 0, 0, 0.58);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 100%;
        transition: box-shadow 0.3s ease-in-out;
    }

    .process-card {
        background-color: #FFFFFF;
        height: 210px;
        width: 180px;
        box-shadow: 3px 3px 10px rgb(0 0 0 / 0.2);
        transition: box-shadow 0.25s ease-in-out;

        &:hover {
            box-shadow: 3px 5px 12px rgba(0, 0, 0, 0.5);
        }

        .failed-completed-row {
            display: flex;
        }

        .inprogress {
            font-size: larger;
            font-weight: bold;
        }
    }

    .v-card__title {
        background-color: #ebdae4;
        padding: 1px;

        &.selected {
            background-color: #d09eba;
        }
    }

}
</style>