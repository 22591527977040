<template>
    <div class="mt-4">
        <v-row>
            <v-col cols="6">
                <v-btn color="primary" depressed class="ma-2 font-weight-bold"
                       to="/candidate" v-if="hasPermission('new_candidate')"
                >{{ $t('candidates.add_candidate') }}
                </v-btn>
                <v-btn color="primary" depressed class="ma-2 font-weight-bold"
                       outlined v-if="hasPermission('new_candidate')"
                       to="/candidates/import">
                    <v-icon small class="ml-1 ">fa-file-excel</v-icon>
                    {{ $t('candidates.import') }}
                </v-btn>
                <v-btn color="primary" depressed class="ma-2 font-weight-bold"
                       outlined v-if="hasPermission('new_candidate')"
                       @click="exportAll()">
                    <v-icon small class="ml-1 ">fa-file-excel</v-icon>
                    {{ $t('candidates.export_all') }}
                </v-btn>
            </v-col>
            <v-col>
                <v-text-field
                    dense
                    class="ma-2"
                    clearable
                    outlined
                    flat
                    :label="$t('candidates.search')"
                    append-outer-icon="fa-search"
                    v-model="tableSearch"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2"
                   v-if="processStat"
                   v-for="(processTitle, processName) in $t('processes_filters')"
                   :key="processName"

            >
                <process-card-filter
                    :process-stat="processStat[processName]"
                    :process-title="processTitle"
                    :process-name="processName"
                    :selected="processName === selectedProcess"
                    :allow-sub-filters="true"
                    @click="processFilterClicked"
                />

            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12" class="mr-1 ml-1">
                <v-data-table
                    @click:row="rowClicked"
                    class="main-table"
                    :headers="headers"
                    :items="filteredCandidates"
                    :search="tableSearch"
                    :loading="loading"
                    :loading-text="$t('candidates.loading')"
                    :no-data-text="$t('candidates.no_data')"
                >
                    <template v-slot:item.is_active="{ item }">
                        {{ $t('is_active')[item.is_active] }}
                    </template>
                    <template v-slot:item.next_stage="{ item }">
                        {{ $t('stages')[selectedProcess][item.next_stage] }}
                    </template>
                    <template v-slot:item.last_progress_date="{ item }">
                        {{ dateFrom(item['last_progress']['updated_at']) }}
                    </template>
                    <template v-slot:item.cbs_active_since="{ item }">
                        {{ item['cbs_active_since'] ? dateFrom(item['cbs_active_since']) : $t('no') }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="left">
                <v-btn
                    color="primary" outlined
                    depressed class="ma-2 mr-5 ml-5 font-weight-bold"
                    @click="exportCurrentTable()"
                    v-text="$t('candidates.export')"
                />
            </v-col>
        </v-row>
    </div>
</template>
<script>

import Store from '@/utils/Store';
import StageFilter from '@/components/stageFilter';
import * as dayjs from 'dayjs';
import 'dayjs/locale/he';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';

import TableExport from '@/utils/TableExport';
import ProcessCardFilter from '@/components/processCardFilter';

dayjs.locale('he')
dayjs.extend(isBetween);
dayjs.extend(relativeTime);

export default {
    name: 'Candidates',
    components: {ProcessCardFilter, StageFilter},
    data() {
        return {
            subFilter: false,
            filterSelectionMenu: false,
            mainFilters: [],
            tableSearch: '',
            tableStatusFilter: [],
            candidates: [],
            stat: [],
            loading: false,
            stageFilter: '',
            filteredList: [],
            dateFilter: [],
            resultFilter: [],
            updateByFilter: '',
            processStat: null,
        }
    },
    watch: {
        stageFilter(current, old) {
            if (current === old) return;

            this.loadFilterData(current);
        },
        mainFilters() {
            Store.setUserSetting('candidates.main_filters', this.mainFilters);
        }
    },
    async created() {

        (Store.getUserSetting('candidates.main_filters') || [])
            .forEach(f => this.mainFilters.push(f));

        await this.getProcessStat();

        if (this.selectedProcess) {
            await this.loadFilterData();
        }

        return this.getCandidates();

    },
    beforeRouteUpdate(to, from, next) {
        next();

        if (!to.query.process)
            return this.getCandidates();

        this.loadFilterData();
    },
    methods: {
        hasPermission: Store.hasPermission,

        processFilterClicked(processName, filter) {

            if (processName === this.selectedProcess && !filter) { // clicked on the same filter, and not on specific sub filter.
                return this.$router.replace({name: 'Candidates'});
            }

            this.$router.replace({name: 'Candidates', query: {process: processName, filter}})
        },

        dateFrom(date) {
            return dayjs(date).from(new Date());
        },

        async getProcessStat() {

            this.loading = true

            try {

                const {data: processStat} = await this.axiosGet('/api/candidates/progress/stat');
                this.processStat = processStat;

            } catch (err) {

                Store.popupsQueue.push({type: 'req_error', err});

            }

            this.loading = false

        },

        async loadFilterData() {

            const filterIdMap = {
                'ongoing': 0,
                'completed': 1,
                'failed': 2,
            }

            const processName = this.selectedProcess;
            const filter = filterIdMap[this.selectedFilter];

            const params = {
                result_in: filter ? [filter] : undefined,
            }

            this.loading = true;
            try {

                const {data} = await this.axiosGet(`/api/candidates/${processName}`, {params});

                this.filteredList = data;

            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            } finally {
                this.loading = false;
            }

        },

        async getCandidates() {

            this.loading = true;

            try {

                const {data} = await this.axiosGet('/api/candidates');
                this.candidates = data;

                // const {data: statData} = await this.axiosGet('/api/candidates/stat');
                // this.stat = statData;

            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }

            this.loading = false;

        },

        rowClicked(value) {

            this.$router.push({
                name: 'Candidate',
                params: {candidateId: value['id']}
            })

        },

        exportCurrentTable() {
            const headers = Array.from(this.headers);
            headers.push(this.$t('candidates.email_header'));
            TableExport(headers, this.filteredCandidates, 'candidates');

        },

        filterIdsHandler(item) {
            return this.filteredIds.includes(item.id);
        },

        filterUpdate(event, data) {
            switch (event.only) {
                case 'only_completed':
                    this.resultFilter = [1];
                    break;
                case 'only_failed':
                    this.resultFilter = [2];
                    break;
                case 'only_completed_or_failed':
                    this.resultFilter = [1, 2];
                    break;
                default:
                    this.resultFilter = [0];
            }

            this.dateFilter = event.date && event.date.length === 2 ? event.date : [];

            this.loadFilterData(data);
        },
        async exportAll() {

            this.loading = true;

            try {

                const {data} = await this.axiosGet('/api/candidates/export');

                const tableData = data.map(candidate => {
                    const {id, idt, is_active, lang, first_name, last_name, phone, site, processes} = candidate;

                    const progressData = {};

                    Object.keys(processes).forEach(process => {
                        Object.entries(processes[process]).forEach(([stageKey, stageData]) => {
                            const value = process + stageKey;
                            progressData[value] = stageData.result;
                        });
                    });

                    return {
                        id, idt, is_active, lang, first_name, last_name, phone, site, ...progressData
                    }
                });

                const processes = this.$t('processes');
                const stages = this.$t('stages');

                //const processColumns = Object.entries(this.$t('processes')).map(([key, value]) => ({text: value, value: key}));

                const additionalColumns = [];

                Object.keys(stages).forEach(process => {
                    const processText = processes[process];
                    Object.entries(stages[process]).forEach(([stageKey, stageText]) => {
                        const text = `${processText} - ${stageText}`;
                        const value = process + stageKey;
                        additionalColumns.push({value, text});
                    });
                });

                const tableHeaders = [{value: 'id', text: this.$t('bulk_update.table_headers.id')}, ...this.$t('candidates.table_headers'), ...additionalColumns];

                TableExport(tableHeaders, tableData, 'all_candidates');

            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            } finally {
                this.loading = false;
            }

        }

    },
    computed: {
        headers() {
            const basicHeaders = this.$t('candidates.table_headers');

            if (!this.selectedProcess) return basicHeaders;

            return [...basicHeaders, ...this.$t('candidates.extra_table_headers')];
        },
        selectedProcess() {
            return this.$route.query.process;
        },
        selectedFilter() {
            return this.$route.query.filter;
        },
        filteredIds() {
            return this.filteredList.map(x => x['id']);
        },
        filteredCandidates() {

            const conditions = [];

            if (this.selectedProcess) {
                conditions.push(this.filterIdsHandler);
            }

            return this.candidates.filter(candidate =>
                conditions.every(condition => condition(candidate)))
                .map(candidate => {

                    const additionalData = this.filteredList.find(c => c.id === candidate.id);

                    if (!additionalData)
                        return candidate;

                    return {
                        ...candidate,
                        ...additionalData,
                        email: `tik${candidate.idt}@node.zyx.bz`
                    }
                });
        },

    },
}
</script>
<style lang="scss">

.main-table {
    box-shadow: 0 4px 19px rgba(202, 230, 241, 0.42);

    .v-data-table-header {
        background-color: #1D8ACA;

        span {
            color: #FFFFFF;
        }

    }
}

.ltr {
    direction: ltr;
}

.white-background {
    background: #FFFFFF;
}
</style>
