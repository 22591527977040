<template>
    <v-snackbar
        :value="showSnackbar"
        timeout="-1"
    >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                v-bind="attrs"
                @click="snackbarButton"
            >
                {{ $t('close') }}
            </v-btn>
        </template>
    </v-snackbar>

</template>

<script>
import Store from '@/utils/Store';

export default {
    name: 'messagePopup',
    data() {
        return {
            showSnackbar: false,
            currentPopupTimer: null,
        }
    },
    watch: {
        currentPopup(newData, oldData) {
            if (!newData) return;
            if (newData === oldData) return;

            return this.showNextPopup();

        }
    },
    computed: {
        currentPopup() {
            return this.popupsQueue[0];
        },
        popupsQueue() {
            return Store.popupsQueue;
        },
        isError() {
            return this.currentPopup && !!this.currentPopup.err;
        },
        message() {
            const popupData = this.currentPopup;
            if (!popupData) return '';

            const errorCodes = this.$t('error_codes');

            if (popupData.type === 'req_error') {
                if (popupData.err.toJSON) {
                  const error = popupData.err.toJSON();
                  return errorCodes[error.status] || errorCodes['default'];
                }
                this.$rollbar.error('req_error', {
                    user: Store.loginData,
                    error: popupData.err,
                });
            }

            if (popupData.type === 'error') {
                return errorCodes[popupData.err] || popupData.err;
            }

            return popupData.message;
        }
    },
    methods: {
        showNextPopup() {
            this.showSnackbar = true;

            this.currentPopupTimer = this.setTimeout(() => {
                this.showSnackbar = false;
                this.popupsQueue.shift();

            }, this.currentPopup.timeout || 2000)
        },
        snackbarButton() {
            clearTimeout(this.currentPopupTimer);
            this.showSnackbar = false;
            this.popupsQueue.shift();
        }
    }
}
</script>

<style scoped>

</style>
