<template>
    <div class="pa-5">
        <h2 class="mb-5" v-text="$t('import_candidate.title')"></h2>
        <v-row>
            <v-col cols="6">
                <v-file-input
                    :disabled="!!importing || loading"
                    v-model="csvFile"
                    show-size
                    dense
                    accept=".csv, text/plain"
                    :label="$t('import_candidate.select_file')"
                >

                </v-file-input>

            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h5 v-text="$t('import_candidate.summary')"></h5>
                <v-row class="pt-5 pb-5 pr-10 pl-10" text-align="center">
                    <v-col cols="8">
                        <v-row>
                            <v-col cols="4" v-for="(text, field, index) in $t('import_candidate.summary_fields')"
                                   :key="index">
                                {{ text }}: <span v-text="importSummary[field]"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4">
                        <v-switch class="ma-0 pa-0"
                                  v-model="errorsOnly"
                                  :label="$t('import_candidate.errors_only')"
                        ></v-switch>
                        <v-switch class="ma-0 pa-0"
                                  v-model="hideErrors"
                                  :label="$t('import_candidate.hide_errors')"
                        ></v-switch>
                    </v-col>
                    <v-col>

                    </v-col>
                </v-row>

            </v-col>
        </v-row>
        <v-row v-if="tableData.length || !!loading">
            <v-col>
                <v-data-table
                    :headers="tableHeaders"
                    :items="tableData"
                    :item-class="setRowClass"
                    :loading="loading"
                    :loading-text="$t('import_candidate.analyzing')"
                >
                    <template v-slot:item.errors="{ item }">
                        <v-chip class="ml-1 mb-1" color="error" style="width: 130px; text-align: center"
                                v-for="(err, i) in item.errors"
                                :key="i"
                        >
                            {{ err }}
                        </v-chip>
                    </template>
                    <template v-slot:footer.prepend>
                        <v-btn
                            color="primary" outlined
                            depressed class="ma-2 mr-5 ml-5 font-weight-bold"
                            @click="exportCurrentTable()"
                        >
                            <v-icon small class="ml-1">fa-file-download</v-icon>
                            {{ $t('candidates.export') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                <v-btn
                    :disabled="!readyToImport.length || loading || !!importing"
                    color="primary"
                    class="font-weight-bold"
                    @click="startImport()"
                    v-text="$t('import_candidate.import')"
                />
            </v-col>
            <v-col align="left">
                <v-btn
                    :disabled="!importing"
                    @click="stopImport = true"
                    v-text="$t('import_candidate.cancel_import')"
                />
            </v-col>
        </v-row>

    </div>
</template>

<script>
import * as Papa from 'papaparse';
import Store from '@/utils/Store';
import sanitizeObject from '@/utils/sanitizeObject';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TableExport from '@/utils/TableExport';
dayjs.extend(customParseFormat);

export default {
    name: 'ImportCandidates',
    data() {
        return {
            loading: false,
            importing: false,
            stopImport: false,
            errorsOnly: false,
            hideErrors: false,
            csvFile: null,
            importData: [],
            imported: [],
            parsedFailed: [],
            importFailed: [],
            duplicates: [],
            internalDuplicatesCounter: 0,
            courses: [],
        }
    },
    watch: {
        csvFile(newFile) {
            if (!newFile) return;
            this.parsedFailed = [];
            this.importFailed = [];
            this.duplicates = [];
            this.imported = [];
            this.importData = [];
            this.internalDuplicatesCounter = 0;

            let encoding = 'windows-1255';

            const reader = new FileReader();

            reader.onload = (evt) => {
                let firstString = this.$t('import_candidate.fields_map.first_name');
                let firstResultString = evt.target.result.split(',')[0];

                encoding = firstString === firstResultString ? encoding : 'utf-8';

                this.parseFile(encoding);
            };

            reader.readAsText(this.csvFile, encoding);

        },
        errorsOnly(newValue) {
            if (newValue && this.hideErrors) {
                this.hideErrors = false;
            }
        },
        hideErrors(newValue) {
            if (newValue && this.errorsOnly) {
                this.errorsOnly = false;
            }
        },

    },
    methods: {
        parseFile(encoding) {
            Papa.parse(this.csvFile, {
                encoding,
                complete: this.parseCompleted,
                header: true,
                transformHeader: headerName => {
                    const foundHeader = Object.entries(this.$t('import_candidate.fields_map')).find(([key, text]) => (text === headerName));
                    return foundHeader && foundHeader[0];
                },
                transform: (value, header) => {
                    if (header === 'lang' && !value) return this.$t('languages')[0];
                    if (header !== 'phone') return value;

                    let phone = value.replace(/[^0-9]/g, '').replace(/^972/, '');
                    if (phone[0] !== '0') {
                        return '0' + phone;
                    }
                    return phone;

                }
            });
        },
        parseCompleted(result) {
            let errorsCount = result.errors.length;
            let lastErrorIndex = errorsCount - 1;
            let lastRowIndex = result.data.length - 1;
            if (
                errorsCount &&
                result.errors[lastErrorIndex].row === lastRowIndex &&
                result.errors[lastErrorIndex].code === 'TooFewFields'
            ) {
                result.errors.pop();
                result.data.pop();
                errorsCount--;
            }
            console.table(result.data);
            this.analyzeData(result.data)
        },
        async analyzeData(newCandidates) {

            const rowErrors = this.$t('import_candidate.row_errors');
            let currentCandidates = [];

            try {

                const {data} = await this.axiosGet('/api/candidates');
                currentCandidates = data;

                const {data: coursesList} = await this.axiosGet('/api/courses');
                this.courses = coursesList;

            } catch (err) {
                return Store.popupsQueue.push({type: 'req_error', err});
            }

            const rules = Store.fieldsRules(this);

            for (let newCandidate of newCandidates) {

                let errors = [];

                // Fields validation
                Object.keys(this.$t('import_candidate.fields_map')).forEach(key => {

                    if (!newCandidate[key] && key !== 'course') {
                        return errors.push(this.$t('import_candidate.table_headers')[key] + ' - ' + rowErrors['missing_required_field']);
                    }

                    let rulesKey;

                    switch (key) {
                        case 'first_name':
                        case 'last_name':
                            rulesKey = 'nameRules';
                            break;
                        case 'lang':
                            rulesKey = 'langRules';
                            break;
                        case 'idt':
                            rulesKey = 'idtRules';
                            break;
                        case 'phone':
                            rulesKey = 'phoneRules';
                            break;
                        case 'site':
                            rulesKey = 'siteRules';
                            break;
                    }

                    if (rulesKey) {
                        errors.push(...rules[rulesKey].map(f => f(newCandidate[key])).filter(x => x !== true));
                    }

                    if (key === 'course' && newCandidate[key]) {
                        const candidateCourseDate = dayjs(newCandidate[key], 'DD/MM/YYYY');
                        let matchCourse = this.courses.find(course =>
                            course.type === 'online' &&
                            newCandidate.site === course.site &&
                            dayjs(course.scheduled_at).isSame(candidateCourseDate, 'date'));

                        if (matchCourse) {
                            newCandidate.courses = [matchCourse.id];
                        } else {
                            errors.push(this.$t('import_candidate.row_errors.unknown_course'));
                        }
                    }

                });

                // Duplicate key on server
                if (currentCandidates.some(cur => cur.idt === newCandidate['idt'])) {
                    errors.push(rowErrors['duplicate_key']);
                    this.duplicates.push(newCandidate);
                }

                // Duplicate key on same data
                const duplicateLookup = newCandidates.filter(candidate => candidate.idt === newCandidate.idt);
                if (duplicateLookup.length > 1) {
                    duplicateLookup.shift();
                    duplicateLookup.forEach(dup => {
                        this.internalDuplicatesCounter++;
                        newCandidates.splice(newCandidates.indexOf(dup), 1);
                    });
                }

                if (errors.length) {
                    newCandidate['errors'] = errors;
                    this.parsedFailed.push(newCandidate);
                }

            }

            this.importData = newCandidates;

        },
        setRowClass(row) {
            if (this.parsedFailed.includes(row)) {
                return 'error-row';
            }
            if (this.imported.includes(row)) {
                return 'imported-row';
            }
            if (this.importing === row.idt) {
                return 'active-import-row';
            }
        },

        async startImport() {

            this.stopImport = false;

            for (const candidate of this.readyToImport) {

                if (this.stopImport) {
                    break;
                }

                this.importing = candidate.idt;

                let shouldBreak = false;

                let sanitizedCandidate = sanitizeObject(candidate,
                    ['courses', ...Object.keys(this.$t('candidate.basic_fields'))]);

                try {

                    await this.axiosPost('/api/candidates/', sanitizedCandidate);

                    this.imported.push(candidate);

                } catch (err) {

                    let errorStrings = this.$t(`import_candidate.row_errors`);
                    let errorDescription = err.isAxiosError && err.response && err.response.data && err.response.data.description;

                    // TODO: Maybe specific error for import?
                    if (errorDescription !== 'duplicate_key') {
                        shouldBreak = true;
                    }

                    Store.popupsQueue.push({type: 'req_error', err});
                    this.importFailed.push(candidate);
                    console.error(err);

                } finally {
                    this.importing = null;
                }

                if (shouldBreak) break;

            }

            // TODO: Did we finish?
        },
        exportCurrentTable() {

            TableExport(this.tableHeaders, this.tableData, 'import_result');

        },
    },
    computed: {
        tableHeaders() {
            const headers = Object.entries(this.$t('import_candidate.table_headers'))
                .map(([value, text]) => ({
                    value, text,
                    sortable: false,
                }));

            headers.find(col => col.value === 'errors').filter = value => {
                if (!this.hideErrors && !this.errorsOnly) return true;
                if (!value && this.errorsOnly) return false;
                return !(value && this.hideErrors);


            };
            return headers;
        },
        tableData() {
            return this.importData.map(row => {
                return row;
            })
        },
        readyToImport() {
            return this.importData.filter(row => {
                if (this.parsedFailed.includes(row)) return;
                if (this.importFailed.includes(row)) return;
                if (this.imported.includes(row)) return;
                return true;
            });
        },
        importSummary() {
            return {
                total_in_file: this.importData.length + this.internalDuplicatesCounter,
                total_to_import: this.readyToImport.length,
                imported: this.imported.length,
                import_errors: this.importFailed.length,
                duplicates: this.duplicates.length,
                rows_error_count: this.parsedFailed.length,
                internal_duplicates: this.internalDuplicatesCounter
            }
        }
    },
    beforeDestroy() {
        this.stopImport = true;
    }
}
</script>
<style lang="scss">
.error-row {
    background-color: #c8cecf;
}

.imported-row {
    background-color: #E9FFD1FF;
}

.active-import-row {
    background-color: #1D8ACA;
}

</style>
