<template>
    <v-app>
        <v-app-bar
            dark
            clipped-right
            height="80px"
            color="#2f394a"
            app v-if="loggedIn">

            <v-row align="center">

                <v-menu class="col-1"
                    bottom
                    left
                    offset-y
                    dark
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>fa-bars</v-icon>
                        </v-btn>
                    </template>

                    <v-list
                        nav
                        color="#2f394a"
                    >
                        <v-list-item
                            v-for="{route, label, icon} in $t('nav_menu')"
                            :key="route"
                            :to="route">
                            <v-list-item-icon>
                                <v-icon>{{ icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="label"/>
                        </v-list-item>

                    </v-list>

                </v-menu>

                <v-col cols="1">
                    <img :title="appVersion" alt="poll logo" src="./assets/logo_2022_inverted.png" class="logo" :class="{pointer: $route.path !== '/dashboard'}"
                         @click="$route.path !== '/dashboard' && $router.push('/dashboard')"
                    />
                </v-col>

                <v-col cols="4" offset-xl="3" offset-lg="3" offset-md="3" offset-sm="1" offset="0">
                    <v-text-field
                        dark
                        ref="idtSearchField"
                        class="ma-3 mt-10 change-error-color"
                        :loading="loading"
                        dense
                        clearable
                        outlined
                        flat
                        :rules="idtRules"
                        :label="$t('app_bar.fast_id_search')"
                        append-icon="fa-search"
                        @click:append="onIdtSearch"
                        v-model="idtSearch"
                        v-on:keyup.enter="onIdtSearch"
                    ></v-text-field>
                </v-col>
                <v-col  align="end">
                    <v-icon class="user-icon">fa-user</v-icon>
                    <span class="mr-3">{{ userDisplayName }}</span>
                    <v-btn
                        @click="logout()"
                        plain
                        rounded
                    >
                        <v-icon class="ml-3">fa-sign-out-alt</v-icon>
                    </v-btn>
                </v-col>

            </v-row>

        </v-app-bar>

        <v-main class="app-background " >
            <v-container class="app-width">
                <router-view></router-view>

                <message-popup></message-popup>

            </v-container>
        </v-main>

        <v-footer app>
            <v-img
                @click="openSite()"
                contain
                class="ma-1" height="30px"
                :title="appVersion"
                alt="CenterPoint Logo" src="./assets/logo_centerpoint.png"/>
        </v-footer>
    </v-app>
</template>

<script>

import Store from '@/utils/Store';
import MessagePopup from '@/components/messagePopup';
import isValidIsraelId from '@/utils/israelIdt';

export default {
    components: {MessagePopup},
    data() {
        return {
            idtSearch: '',
            items: [],
            loading: false,
            idtRules: [v => !v || (isValidIsraelId(v)) || this.$t('new_candidate.invalid_idt'),],
        }
    },
    methods: {
        openSite() {
            window.open('https://www.centerpoint.co.il');
        },
        logout() {
            this.axiosPost('/api/auth/logout')
                .then(() => {
                    Store.loginData = {};
                    this.$router.push('/login')
                })
        },
        async onIdtSearch() {
            if (!this.idtSearch || !this.$refs.idtSearchField.valid)
                return this.idtSearch = '';

            this.loading = true;

            try {
                const {data} = await this.axiosGet('/api/candidates/search', {params: {idt: this.idtSearch}});

                if (!data) {
                    return Store.popupsQueue.push({type: 'info', message: this.$t('app_bar.candidate_not_found')});
                }

                if (this.$route.params.candidateId == data['id']) {
                    return Store.popupsQueue.push({type: 'info', message: this.$t('app_bar.candidate_already_open')});
                }

                this.idtSearch = '';

                return this.$router.push({
                    name: 'Candidate',
                    params: {candidateId: data['id']}
                });

            } catch (err) {

                Store.popupsQueue.push({type: 'req_error', err});

            } finally {
                this.loading = false;
            }
        },
        goBack() {
            this.$router.go(-1)
        },
    },
    computed: {
        loggedIn() {
            return Store.loginData.user_id;
        },
        userDisplayName() {

            const {first_name, last_name} = Store.loginData;
            return ((first_name || '') + ' ' + (last_name || '')).trim();
        },
        appVersion() {
            return 'Version: ' + process.env.VUE_APP_VERSION;
        }
    }
}
</script>

<style lang="scss">

@font-face {
    font-family: 'OpenSansHebrew';
    src: local('OpenSansHebrew'),
    url(./fonts/OpenSansHebrew/OpenSansHebrew-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansHebrew';
    src: local('OpenSansHebrew'),
    url(./fonts/OpenSansHebrew/OpenSansHebrew-Bold.ttf) format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansHebrew';
    src: local('OpenSansHebrew'),
    url(./fonts/OpenSansHebrew/OpenSansHebrew-Italic.ttf) format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSansHebrew';
    src: local('OpenSansHebrew'),
    url(./fonts/OpenSansHebrew/OpenSansHebrew-Light.ttf) format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansHebrew';
    src: local('OpenSansHebrew'),
    url(./fonts/OpenSansHebrew/OpenSansHebrew-BoldItalic.ttf) format("truetype");
    font-weight: bold;
    font-style: italic;
}

$font-family: 'OpenSansHebrew';

.v-application {
    [class*='text-'] {
        color: #36405a;
        font-family: $font-family, sans-serif !important;
    }
    font-family: $font-family, sans-serif !important;

    .change-error-color {
        .error--text {
            color: #ff3636 !important;
            caret-color: #ff3636 !important;
        }
    }

}

i.v-icon.v-icon {
    &.user-icon {
        color: #FFFFFF;
        background: rgba(182, 215, 230, 0.42);
        padding: 10px;
        border-radius: 50%;
    }
}

.logo {
    height: 60px;
}
.pointer {
    cursor: pointer;
}

.app-background {
    background-image: url('~@/assets/background.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.container.app-width {
    min-width: 1270px;
    max-width: 1185px;
}


</style>
