<template>
    <v-row class="white-background mt-9">

        <v-col cols="12" class="pa-5">
            <v-icon large class="user-icon">fa-user</v-icon>
            <span class="title font-weight-bold mr-3">{{ pageTitle }}</span>
        </v-col>

        <v-col cols="12">
            <v-form ref="form" v-model="valid" class="row pa-8 justify-center">

                <v-text-field class="col-3 mr-1 ml-1" dense outlined flat
                              :label="$t('candidate.basic_fields.first_name')"
                              v-model="userDetails.first_name"
                              :rules="nameRules"
                />
                <v-text-field class="col-3 mr-2 ml-2" dense outlined flat
                              :label="$t('candidate.basic_fields.last_name')"
                              v-model="userDetails.last_name"
                              :rules="nameRules"
                />
                <v-text-field class="col-3 mr-2 ml-2" dense outlined flat
                              :label="$t('candidate.basic_fields.idt')"
                              v-model="userDetails.idt"
                              :rules="idtRules"
                              :disabled="!!candidateId"
                />

                <v-select class="col-3 mr-2 ml-2" dense outlined flat
                          :label="$t('candidate.basic_fields.lang')"
                          v-model="userDetails.lang"
                          :items="$t('languages')"
                          :rules="langRules"
                />

                <v-text-field class="col-3 mr-2 ml-2" dense outlined flat
                              :label="$t('candidate.basic_fields.phone')"
                              v-model="userDetails.phone"
                              :rules="phoneRules"
                />
                <v-select class="col-3 mr-2 ml-2" dense outlined flat
                          :label="$t('candidate.basic_fields.site')"
                          v-model="userDetails.site"
                          :items="$t('sites')"
                          :rules="siteRules"
                />
            </v-form>
        </v-col>

        <v-col align="left" class="pr-10 pl-10 mb-5">
            <v-btn
                :disabled="saving || loading"
                color="primary"
                depressed
                outlined
                class="pr-8 pl-8"
                @click="goBack()">{{ $t('cancel') }}
            </v-btn>
            <v-btn
                :disabled="saving || loading"
                color="primary"
                depressed
                class="mr-5 font-weight-bold pr-8 pl-8"
                @click="save">{{ $t('save') }}
            </v-btn>
        </v-col>

    </v-row>
</template>

<script>

import MessagePopup from '@/components/messagePopup';
import Store from '@/utils/Store';
import sanitizeObject from '@/utils/sanitizeObject';

export default {
    name: 'NewEditCandidate',
    components: {MessagePopup},
    data() {
        return {
            loading: false,
            saving: false,
            valid: false,
            userDetails: {
                first_name: '',
                last_name: '',
                idt: '',
                phone: '',
                lang: this.$t('languages')[0],
                site: '',
            },
            currentUserDetails: {},
            ...Store.fieldsRules(this),

        }
    },
    computed: {
        candidateId() {
            return this.$route.params.candidateId;
        },
        pageTitle() {
            return this.candidateId ? this.$t('new_candidate.title_edit', {idt: this.currentUserDetails.idt}) : this.$t('new_candidate.title_new');
        }
    },
    created() {
        if (!this.candidateId) return;
        return this.getCurrentData();
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        async getCurrentData() {

            this.loading = true;

            try {
                const {data} = await this.axiosGet(`/api/candidates/${this.candidateId}`);

                this.currentUserDetails = data;

                this.userDetails.idt = data.idt;
                this.userDetails.first_name = data.first_name;
                this.userDetails.last_name = data.last_name;
                this.userDetails.phone = data.phone;
                this.userDetails.lang = data.lang;
                this.userDetails.site = data.site;

            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err})
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (!this.valid) {
                Store.popupsQueue.push({type: 'error', err: this.$t('new_candidate.fields_error')})
                return this.$refs.form.validate();
            }

            this.saving = true;

            let sanitizedCandidate = sanitizeObject(this.userDetails,
                Object.keys(this.$t('candidate.basic_fields')));

            try {

                const {data} = await this
                    .axiosPost(`/api/candidates${this.candidateId ? '/' + this.candidateId : ''}`,
                        sanitizedCandidate);

                Store.popupsQueue.push({type: 'success', message: this.candidateId ? this.$t('candidate.saved') : this.$t('new_candidate.created')});

                if ((this.userDetails.site !== this.currentUserDetails.site) || this.userDetails.lang !== this.currentUserDetails.lang) {
                    alert(this.$t('new_candidate.map_course'));
                }
                return this.$router.push('/candidate/' + data['id']);

            } catch (err) {

                Store.popupsQueue.push({type: 'req_error', err});

            } finally {
                this.saving = false;
            }
        },
    },
}
</script>