<template>
    <div>
        <v-data-table
            :loading="loading"
            class="pa-5"
            :items="filteredCourses"
            :headers="tableHeaders"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title class="font-weight-bold">{{ $t('courses.title') }}</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-btn
                        v-show="dateFilter.length === 2 || siteFilter || typeFilter"
                        v-text="$t('filters.clear_all')"
                        @click="clearFilters()"
                        color="primary"
                        outlined
                    ></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="hasPermission('new_course')"
                        class="font-weight-bold"
                        v-text="$t('courses.new_course')"
                        @click="editItem()"
                        color="primary"
                    />
                </v-toolbar>
            </template>
            <template v-slot:header.type="{ header }">
                <span v-text="header.text"/>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="typeFilter ? 'primary' : ''">
                                fa-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div class="white">
                        <v-radio-group v-model="typeFilter">
                            <v-radio
                                :label="$t('filters.all')"
                                value=""
                                class="ma-1"
                            ></v-radio>
                            <v-radio
                                v-for="(label, value) in $t('courses.types')"
                                :key="value"
                                :label="label"
                                :value="value"
                                class="ma-1"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.site="{ header }">
                <span v-text="header.text"/>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="siteFilter ? 'primary' : ''">
                                fa-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div class="white">
                        <v-radio-group v-model="siteFilter">
                            <v-radio
                                :label="$t('filters.all')"
                                value=""
                                class="ma-1"
                            ></v-radio>
                            <v-radio
                                v-for="site in $t('sites')" :key="site"
                                :label="site"
                                :value="site"
                                class="ma-1"
                            >{{ site }}
                            </v-radio>
                        </v-radio-group>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.scheduled_at="{ header }">
                <span v-text="header.text"/>
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="dateFilter.length === 2 ? 'primary' : ''">
                                fa-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div class="white">
                        <v-date-picker
                            v-model="dateFilter"
                            range
                            scrollable
                            no-title
                        ></v-date-picker>
                    </div>
                </v-menu>
            </template>
            <template v-slot:item.type="{ item }">
                {{ $t('courses.types')[item.type] }}
            </template>
            <template v-slot:item.scheduled_at="{ item }">
                {{ formatDateTime(item.scheduled_at) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    :title="$t('courses.table_actions.list')"
                    @click="courseClicked(item)"
                >fa-list
                </v-icon>
                <v-icon v-if="hasPermission('edit_course')"
                    :title="$t('courses.table_actions.edit')"

                    class="mr-2"
                    @click="editItem(item)"
                >fa-edit
                </v-icon>
                <v-icon v-if="hasPermission('delete_course')"
                    :title="$t('courses.table_actions.delete')"

                    class="mr-2"
                    @click="deleteItem(item)"
                >fa-trash
                </v-icon>
            </template>

        </v-data-table>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
            <template v-slot:activator="{on, attrs}">

            </template>
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="4"
                            >
                                <p v-text="$t('courses.table_headers.type')" class="font-weight-bold"/>
                                <v-radio-group v-model="editedItem.type" ref="type">
                                    <v-radio
                                        v-for="(label, value) in $t('courses.types')"
                                        :key="value"
                                        :label="label"
                                        :value="value"
                                        :rules="notEmptyRule"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="8">
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-text-field ref="schedule"
                                            type="datetime-local"
                                            v-model="editedItem.scheduled_at"
                                            :label="$t('courses.table_headers.scheduled_at')"
                                            :rules="notEmptyRule"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="6"
                                    >
                                        <v-text-field ref="description"
                                            v-model="editedItem.description"
                                            :label="$t('courses.table_headers.description')"
                                            :rules="notEmptyRule"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="6"
                                    >
                                        <v-select ref="site"
                                            v-model="editedItem.site"
                                            :label="$t('courses.table_headers.site')"
                                            :items="$t('sites')"
                                            :rules="siteRules"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="darken-1"
                        text
                        @click="close"
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="!validated"
                        color="blue darken-1"
                        text
                        @click="save"
                    >
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="">{{ $t('courses.approve_delete') }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ $t('ok') }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDuplicate" max-width="560px">
            <v-card>
                <v-card-title class="">{{ $t('courses.duplicate') }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialogDuplicate = false">{{ $t('cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="duplicateConfirmed()">{{ $t('ok') }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import Store from '@/utils/Store';
import * as dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const getNextBusinessDay = date => {
    let nextDay = dayjs(date).add(1, 'day');
    if (nextDay.day() > 4) {
        return getNextBusinessDay(nextDay);
    }
    return nextDay;
}

export default {
    name: 'courses',
    data() {
        return {
            loading: false,
            courses: [],
            dialog: false,
            dialogDelete: false,
            dialogDuplicate: false,
            duplicateConfirm: false,
            editedIndex: -1,
            editedItem: {
                id: '',
                type: '',
                description: '',
                site: '',
                scheduled_at: '',
            },
            defaultItem: {
                id: '',
                type: '',
                description: '',
                site: '',
                scheduled_at: '',
            },
            duplicateItem: {},
            typeFilter: '',
            siteFilter: '',
            dateFilter: [],
            ...Store.fieldsRules(this),
        }
    },
    methods: {
        hasPermission: Store.hasPermission,
        clearFilters() {
            this.typeFilter = '';
            this.siteFilter = '';
            this.dateFilter = [];
        },
        async loadData() {
            this.loading = true;
            try {
                const {data} = await this.axiosGet('/api/courses', {params: {count: 1}});
                this.courses = data;
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }
            this.loading = false;
        },
        formatDateTime(date) {
            return dayjs(date).format('DD/MM/YYYY - HH:mm')
        },
        async postCourse(course) {
            try {
                await this.axiosPost('/api/courses' + (course.id ? `/${course.id}` : ''), course);
                Store.popupsQueue.push({type: 'success', message: this.$t('courses.saved_success')});
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }
        },
        async deleteCourse(course) {
            try {
                await this.axios('/api/courses/' + course.id, {method: 'delete'});
                Store.popupsQueue.push({type: 'success', message: this.$t('courses.delete_success')});
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }
        },

        editItem(item) {
            let schedule = item && item.scheduled_at || new Date;

            this.editedIndex = this.courses.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editedItem.scheduled_at = dayjs(schedule).format('YYYY-MM-DDTHH:mm');

            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.courses.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            await this.deleteCourse(this.editedItem);
            await this.loadData();
            this.closeDelete()
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            });
            if (this.$route.query.new) {
                this.$router.replace('/courses');
            }
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        courseClicked(value) {
            this.$router.push({
                name: 'Course',
                params: {courseId: value['id']}
            });
        },
        async save() {
            await this.postCourse(this.editedItem);
            if (this.isNewCourse && this.editedItem.type === 'online') {
                this.duplicateItem = Object.assign({}, this.editedItem);
                this.duplicateItem.type = 'front';
                this.duplicateItem.scheduled_at = getNextBusinessDay(this.editedItem.scheduled_at);
                this.dialogDuplicate = true;
            }
            await this.loadData();
            this.close()
        },
        typeFilterHandler(item) {
            return item.type === this.typeFilter;
        },
        siteFilterHandler(item) {
            return item.site === this.siteFilter;
        },
        dateFilterHandler(item) {
            const [startDate, endDate] = this.dateFilter;
            return dayjs(item.scheduled_at).isBetween(startDate, endDate, 'date', '[]')
        },
        duplicateConfirmed() {
            this.dialogDuplicate = false;
            this.editItem(this.duplicateItem);
        }

    },
    created() {
        this.loadData();

        if (this.$route.query.new) {
            this.editItem();
        }

        const {typeFilter, siteFilter, dateFilter} = Store.getUserSetting('courses.filters');

        this.typeFilter = typeFilter;
        this.siteFilter = siteFilter;
        this.dateFilter = dateFilter || [];
    },
    computed: {
        tableHeaders() {
            return Object.entries(this.$t('courses.table_headers')).map(([value, text]) => ({value, text}));
        },
        isNewCourse() {
            return this.editedIndex === -1;
        },
        validated() {
            let {type, description, scheduled_at, site} = this.editedItem;
            return type && description && scheduled_at && site;
        },
        formTitle() {
            return this.isNewCourse ?
                this.$t('courses.new_course') :
                this.$t('courses.edit_course', this.editedItem)
        },
        filteredCourses() {

            const conditions = [];

            if (this.typeFilter)
                conditions.push(this.typeFilterHandler);

            if (this.siteFilter)
                conditions.push(this.siteFilterHandler);

            if (this.dateFilter.length === 2)
                conditions.push(this.dateFilterHandler);

            Store.setUserSetting('courses.filters', {
                typeFilter: this.typeFilter || undefined,
                siteFilter: this.siteFilter || undefined,
                dateFilter: this.dateFilter.length === 2 ? this.dateFilter : undefined,
            });

            if (!conditions.length) {
                return this.courses;
            }

            return this.courses.filter(course =>
                conditions.every(condition =>
                    condition(course)))

        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

}
</script>