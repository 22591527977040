module.exports = {
    login: {
        app_header: 'מפקד האוכלוסין 2022',
        app_subheader: 'מערכת הגיוס',
        username: 'שם משתמש',
        password: 'סיסמא',
        login: 'כניסה',
    },
    app_bar: {
        logout: 'ניתוק',
        fast_id_search: 'גישה מהירה למועמד לפי מספר זהות',
        only_numbers: 'שדה זה אמור להכיל אך ורק ספרות',
        candidate_not_found: 'לא נמצא מועמד',
        candidate_already_open: 'המועמד כבר פתוח בדף זה',
    },
    nav_menu: [
        {
            route: '/dashboard',
            label: 'דו"ח מצב',
            icon: 'fa-chart-line',
        },
        {
            route: '/candidates',
            label: 'מועמדים',
            icon: 'fa-address-card',
        },
        {
            route: '/courses',
            label: 'הדרכות',
            icon: 'fa-chalkboard-teacher',
        },
        {
            route: '/users',
            label: 'משתמשים',
            icon: 'fa-users',
        },
    ],
    processes: {
        security_docs: 'מסמכי ר.פ',
        security_check: 'בדיקת ר.פ',
        campus_user: 'משתמש בקמפוס',
        online_course: 'הדרכה אונליין',
        front_course: 'הדרכה פרונטלית',
        certificate: 'הסמכה',
    },
    processes_filters: {
        security_docs: 'מסמכי ר.פ',
        security_check: 'בדיקות ר.פ',
        campus_user: 'משתמשים בקמפוס',
        online_course: 'הדרכות אונליין',
        front_course: 'הדרכות פרונטלית',
        certificate: 'הסמכות',
    },
    stages: {
        security_docs: {
            send_link: 'שליחת לינק',
            cbs_update: 'למ"ס אישרו',
        },
        security_check: {
            cbs_update: 'למ"ס אישרו ר.פ',
        },
        campus_user: {
            user_creation: 'יצירת משתמש',
            cbs_update: 'למ"ס ביצעו שיוך',
        },
        online_course: {
            schedule_course: 'שיבוץ להדרכה',
            send_reminder: 'תזכורת הגעה',
            presence_result: 'דיווח נוכחות',
            cbs_update: 'למ"ס אישרו',
        },
        front_course: {
            schedule_course: 'שיבוץ להדרכה',
            presence_result: 'דיווח נוכחות',
            cbs_update: 'למ"ס אישרו',
        },
        certificate: {
            cbs_update: 'למ"ס הנפיקו',
        }
    },
    dashboard: {
        welcome_title: 'ברוכים הבאים',
        welcome_subtitle: 'למערכת גיוס סוקרים',
        total: 'מועמדים במערכת',
        count_active: 'סה"כ פעילים',
        count_course: 'ממתינים להדרכה',
        count_pass_course: 'התחילו הדרכה',
        count_completed: 'קיבלו הסמכה',
        active: 'פעילים',
        not_active: 'לא פעילים',
        common_actions: 'פעולות נפוצות',
        add_candidate: 'הוספת מועמד בודד',
        import_candidates: 'ייבוא קובץ מועמדים',
        add_user: 'הוספת משתמש מערכת',
        add_course: 'הוספת הדרכה',

        completed: 'השלימו:',
        ongoing: 'בתהליך:',
        failed: 'נכשלו:',
        wait_for_cbs_update: 'ממתינים ללמ"ס',

        cbs_bulk_update: 'טעינת קובץ עדכון',

    },
    candidates: {
        table_headers: [
            {text: 'שם פרטי', value: 'first_name'},
            {text: 'שם משפחה', value: 'last_name'},
            {text: 'מספר זהות', value: 'idt'},
            {text: 'שפה', value: 'lang'},
            {text: 'טלפון', value: 'phone'},
            {text: 'אתר', value: 'site'},
            {text: 'מועמד פעיל', value: 'is_active'}
        ],
        extra_table_headers: [
            {text: 'ממתין לשלב', value: 'next_stage'},
            {text: 'תאריך עדכון אחרון', value: 'last_progress_date'},
            {text: 'ממתין ללמ"ס', value: 'cbs_active_since'},
        ],
        email_header: {text: 'דואר אלקטרוני', value: 'email'},
        clear_main_filters: 'הסר את כל המסננים',
        completed: 'סיימו:',
        failed: 'נכשלו:',
        init: 'ממתינים לשלב זה:',
        add_candidate: 'הוסף מועמד',
        export: 'ייצוא',
        search: 'חיפוש חופשי',
        status_filter: 'הצג מועמדים בסטטוס',
        import: 'ייבוא מקובץ',
        loading: 'טוען מועמדים...',
        no_data: 'רשימת המועמדים ריקה',
        export_all: 'ייצוא כל הנתונים'
    },
    candidate: {
        title: 'פרטי מועמד',
        history: 'היסטוריה',
        saved: 'המועמד נשמר בהצלחה',
        campus_user_created: 'נוצר משתמש בקמפוס',
        campus_user_exist: 'כבר קיים משתמש בקמפוס',
        attached_fields: 'קבצים מצורפים',
        missing_info: '--מידע חסר--',
        edit: 'עריכת מועמד',
        disable: 'הסרת מועמד',
        reactivate: 'החזרת מועמד',
        reactivated: 'המועמד חזר לתהליך בהצלחה',
        delete: 'מחיקת מועמד',
        delete_confirm: 'המועמד יימחק לצמיתות ופרטיו לא ישמרו במידה ויחזור בעתיד! בטוח שברצונך למחוק את המועמד?',
        deleted: 'מחיקת המועמד התבצעה בהצלחה',
        disabled: 'הסרת המועמד הסתיימה',
        is_disabled: 'המועמד אינו פעיל',
        basic_fields: {
            first_name: 'שם פרטי:',
            last_name: 'שם משפחה:',
            idt: 'מספר זהות:',
            lang: 'שפה:',
            phone: 'טלפון:',
            site: 'אתר:',
        },
        campus_details: {
            title: 'פרטי כניסה לקמפוס',
            email: 'דואר אלקטרוני:',
            password: 'סיסמה:'
        },
        online_course_button: 'שיוך להדרכה מקוונת',
        front_course_button: 'שיוך להדרכה פרונטלית',
        course_select: 'בחר הדרכה מהרשימה',
        no_matching_courses: 'אין הדרכות תואמות באתר זה',
        course_already_assigned: 'המועמד כבר משויך להדרכה זו',
        no_history: 'אין היסטוריה למועמד',
        updated_by: 'על ידי: {name}',
        results: {
            0: 'בוטל',
            1: 'עבר',
            2: 'נכשל',
            3: 'נכשל',
        }
    },
    new_candidate: {
        title_new: 'הוספת מועמד',
        title_edit: 'עריכת מועמד - {idt}',
        field_required: 'שדה זה הכרחי',
        field_too_long: 'השדה ארוך מדי',
        invalid_idt: 'מספר הזהות שגוי',
        invalid_phone: 'מספר הטלפון שגוי',
        unknown_site: 'אתר לא מוכר',
        unknown_lang: 'שפה לא נתמכת',
        fields_error: 'יש לתקן את השגיאות בשדות המסומנים',
        created: 'המועמד נוצר בהצלחה',
        map_course: 'שימו לב: לאחר שינוי אתר או שפה - חשוב לבדוק אם יש צורך בשיוך להדרכה אחרת!'
    },
    import_candidate: {
        title: 'יבוא מועמדים',
        select_file: 'בחר קובץ לייבוא',
        summary: 'סיכום',
        analyzing: 'מבצע ניתוח מידע לפני ייבוא...',
        errors_only: 'הצג שגיאות בלבד',
        hide_errors: 'הסתר שגיאות',
        summary_fields: {
            total_in_file: 'רשומות בקובץ',
            total_to_import: 'מוכנים לייבוא',
            imported: 'יובאו בהצלחה',
            internal_duplicates: 'כפילויות בקובץ',
            rows_error_count: 'שורות עם שגיאות',
            duplicates: 'כבר קיימים במערכת',
        },
        fields_map: {
            first_name: 'שם פרטי',
            last_name: 'שם משפחה',
            idt: 'ת.ז',
            lang: 'שפה',
            phone: 'טלפון ראשי',
            site: 'תאור תפקיד',
            course: 'תאריך אירוע'
        },
        table_headers: {
            first_name: 'שם פרטי',
            last_name: 'שם משפחה',
            idt: 'מספר זהות',
            lang: 'שפה',
            phone: 'טלפון',
            site: 'אתר',
            course: 'תאריך הדרכה',
            errors: 'שגיאות'
        },
        row_errors: {
            missing_required_field: 'שדה חובה',
            duplicate_key: 'מזהה מועמד כבר קיים במערכת',
            unknown_course: 'לא נמצאה הדרכה במועד זה',
            unknown: 'שגיאה לא ידועה',
        },
        import: 'בצע ייבוא',
        cancel_import: 'עצור ייבוא'
    },
    bulk_update: {
        title: 'עדכון גורף',
        subtitle: 'עבור מועמדים בתהליך: {process}',
        download_template: 'הורדת תבנית',
        select_file: 'בחר קובץ עדכון',
        summary: 'סיכום',
        analyzing: 'מבצע ניתוח מידע לפני עדכון...',
        errors_only: 'הצג שגיאות בלבד',
        hide_errors: 'הסתר שגיאות',
        summary_fields: {
            total_in_file: 'רשומות בקובץ',
            total_to_import: 'מוכנים לעדכון',
            imported: 'עודכנו בהצלחה',
            internal_duplicates: 'כפילויות בקובץ',
            rows_approved: 'עברו בהצלחה',
            rows_failed: 'נכשלו',
            rows_error_count: 'שורות עם שגיאות',
            rows_failed_after_success: 'שגיאות מסוג נכשלו לאחר שעברו',
        },
        fields_map: {
            idt: 'מזהה',
            result: 'תוצאה'
        },
        table_headers: {
            id: 'מזהה פנימי',
            idt: 'מספר זהות',
            result: 'תוצאה',
            errors: 'שגיאות'
        },
        row_errors: {
            missing_required_field: 'שדה חובה',
            missing_candidate: 'מזהה מועמד לא קיים',
            failed_after_success: 'נכשל לאחר שעבר!',
            unknown: 'שגיאה לא ידועה',
        },
        import: 'בצע עדכון',
        cancel_import: 'עצור עדכון',
        completed_keys: [
            'מוסמך',
            'הוסמך',
            'שוייך',
            'עבר',
            'ממליץ',
            'אושר',
        ],
        file_headers: {
            security_docs: 'מילוי טופס מקוון - תקשוב',
            security_check: 'בדיקת ר.פ - תקשוב',
            campus_user: 'שיוך לקורס מקוון - תקשוב',
            online_course: 'ביצוע קורס מקוון - תקשוב',
            front_course: 'ביצוע הדרכה פרונטלית - תקשוב',
            certificate: 'קבלת הסמכה - תקשוב',
        },
        template_match_error: 'הקובץ לא מתאים לתבנית שנקבעה!'
    },
    users: {
        title: 'רשימת משתמשים',
        new_user: 'משתמש חדש',
        edit_user: 'עריכת משתמש',
        approve_delete: 'האם אתם בטוחים שברצונכם למחוק את המשתמש?',
        deleted: 'המשתמש נמחק בהצלחה',
        table_headers: {
            id: 'מזהה פנימי',
            username: 'שם משתמש',
            first_name: 'שם פרטי',
            last_name: 'שם משפחה',
            work_email: 'כתובת מייל',
            phone: 'טלפון',
            gender: 'מין',
            comment: 'הערות',
            role: 'תפקיד',
            actions: 'פעולות',
        },
        user_fields: {
            id: 'מזהה פנימי',
            username: 'שם משתמש',
            first_name: 'שם פרטי',
            last_name: 'שם משפחה',
            work_email: 'כתובת מייל',
            phone: 'טלפון',
            gender: 'מין',
            comment: 'הערות',
            password: 'סיסמא',
            role_id: 'תפקיד',
        },
        password_alert: `הסיסמא של המשתמש החדש היא: <b>{password}</b><br>
        יש להעתיק ולשמור במקום בטוח. לא ניתן לאפס או לשחזר את הסיסמא!<br>
        מידע זה ייעלם בעוד מספר שניות`
    },
    courses: {
        title: 'רשימת הדרכות',
        new_course: 'הדרכה חדשה',
        edit_course: 'עריכת הדרכה: {description} - מזהה: {id}',
        approve_delete: 'האם אתם בטוחים שברצונכם למחוק את ההדרכה?',
        duplicate: 'האם ברצונך ליצור גם הדרכה פרונטלית תואמת ביום העסקים הבא?',
        delete_success: 'ההדרכה נמחקה בהצלחה',
        saved_success: 'ההדרכה נשמרה בהצלחה',
        table_headers: {
            id: 'מזהה פנימי',
            type: 'סוג הדרכה',
            description: 'תיאור',
            site: 'אתר',
            scheduled_at: 'בתאריך',
            candidates: 'מועמדים משויכים',
            actions: 'פעולות',
        },
        table_actions: {
            list: 'רשימת מועמדים',
            edit: 'עריכה',
            delete: 'מחיקה'
        },
        new_course_fields: {
            id: 'מזהה פנימי',
            type: 'סוג הדרכה',
            description: 'תיאור',
            site: 'אתר',
            scheduled_at: 'בתאריך',
        },
        types: {
            online: 'מקוונת',
            front: 'פרונטלית',
        }
    },
    course: {
        title: 'פרטי ההדרכה',
        basic_fields: {
            id: 'מזהה הדרכה',
            type: 'סוג הדרכה',
            description: 'תיאור',
            site: 'אתר',
            scheduled_at: 'בתאריך',
        },
        candidates_table: {
            idt: 'מספר זהות',
            first_name: 'שם פרטי',
            last_name: 'שם משפחה',
            phone: 'טלפון',
            lang: 'שפה',
            email: 'דואר אלקטרוני'
        },
        presence_approve: 'אישור נוכחות',
        presence_decline: 'לא הגיע',
        presence_not_finished: 'הגיע ולא סיים',
        send_reminder: 'שליחת תזכורת',
        reminder_approve: 'אישור תזכורת',

        actions_completed: 'העדכונים נשמרו בהצלחה',

        table_title: 'מועמדים משויכים',
        table_no_candidates: 'אף מועמד לא שויך עדיין להדרכה זו'

    },
    sites: [
        "כרמיאל - המפקד",
        "כרמיאל - פניות הציבור",
        "עפולה",
        "אשקלון",
        "דימונה",
        "קדמת גליל",
        "טבריה",
        "מג'אר",
        "תקשוב מהבית",
        "מנהל צוות",
        "מנהל מוקד",
        "אחמ\"ש",
        "תקשוב מהבית – נתניה",
        "תקשוב מהבית – טבריה",
        "תקשוב מהבית – אשקלון",
        "תקשוב מהבית – נתיבות",
        "תקשוב מהבית – צ'ק פוסט",
        "תקשוב מהבית – ירושלים",
        "תקשוב מהבית - עפולה",
        "נשר",
        "מעודדי השבה - תקשוב מהבית",
        "מעודדי השבה - אשקלון"
    ],
    languages: [
        'עברית',
        'ערבית',
        'רוסית'
    ],
    error: 'שגיאה',
    error_codes: {
        'not_found': 'המשאב שביקשת אינו קיים.',
        'not-allowed': 'אינך מורשה לבצע פעולה זו.',
        'authorization-error': 'עליך להתחבר למערכת על מנת לבצע פעולה זו.',
        'invalid-email': 'כתובת האימייל שסופקה אינה תקינה.',
        'username-in-use': 'שם המשתמש שסופק הינו כבר בשימוש.',
        'email-in-use': 'כתובת האימייל שסופקה הינה כבר בשימוש.',
        'empty-file': 'הקובץ שהועלה הינו קובץ ריק.',
        'duplicate_key': 'לא ניתן לבצע את הפעולה עקב שגיאת מפתח כפול',
        'ami-trouble': 'שגיאת מרכזיה, נא לפנות למנהל המערכת.',
        '403-no-match': 'שם המשתמש או הסיסמא אינם תואמים.',
        '403-locked': 'חשבונך נעול. נא צור קשר עם מנהל המערכת שלך.',
        '403': 'אינך מורשה לבצע פעולה זו.',
        '400': 'בקשה לא תקינה נשלחה אל השרת. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '401': 'עליך להתחבר למערכת על מנת לבצע פעולה זו.',
        '402': 'הפעולה נדחתה על ידי השרת עקב תשלומים ממתינים.',
        '404': 'המשאב שביקשת אינו קיים.',
        '409': 'לא ניתן לבצע את הפעולה עקב התנגשות בנתונים',
        '405': 'בקשה לא תקינה נשלחה אל השרת. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '500': 'אירעה שגיאת שרת פנימית. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '501': 'בקשה לא מוכרת נשלחה אל השרת. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '502': 'נראה שאתה משתמש בשרת Proxy כושל. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '503': 'השירות אינו זמין כרגע. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '504': 'נראה שאתה משתמש בשרת Proxy כושל. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        'default': 'אירעה שגיאה לא מוכרת. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.'
    },
    filters: {
        all: 'הכל',
        clear: 'נקה',
        clear_all: 'נקה סינונים',
        only_completed: 'הצג רק עברו',
        only_failed: 'הצג רק נכשלו',
        only_completed_or_failed: 'הצג רק עברו או נכשלו',
        date_range: 'בטווח תאריכים',
        course: 'משובצים להדרכה',
        and_others: 'ו {count} אחרים',
        select_stages: 'סנן לפי שלב',
        bulk_update: 'העלאת קובץ עדכון'
    },
    loading: 'טוען...',
    close: 'סגירה',
    back: 'חזרה',
    save: 'שמירה',
    apply: 'החל',
    cancel: 'ביטול',
    ok: 'כן',
    no: 'לא',
    is_active: ['לא', 'כן'],
    no_permission: 'יש להתחבר עם משתמש בעל הרשאות מתאימות'
}
