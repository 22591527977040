<template>
    <v-card :outlined="selected" :flat="flat"
            @click="$emit('click')"
    >
        <v-card-title>
            <span class="font-weight-bold ml-1" v-text="$t('processes')[process]"/>
            > {{ $t('stages')[process][stage] }}
        </v-card-title>
        <v-card-title v-text="$t('candidates.init') + ' ' + stat.init" class="justify-center font-weight-bold"/>

        <v-row>
            <v-col>
                <v-card-text>
                    <div class="font-weight-bold">
                        {{ $t('candidates.completed') }}
                        <span class="font-weight-regular">{{ stat.completed }}</span>
                    </div>
                    <div class="font-weight-bold">
                        {{ $t('candidates.failed') }}
                        <span class="font-weight-regular">{{ stat.failed }}</span>
                    </div>

                </v-card-text>
            </v-col>
            <v-col cols="4" class="mt-5">
                <v-btn v-if="bulkUpdateButton"
                       outlined
                       color="primary"
                       v-text="$t('filters.bulk_update')"
                       :to="`/candidates/bulk_update/${process}`"
                ></v-btn>
            </v-col>
            <v-col cols="3">
                <v-menu
                    v-model="filterMenu"
                    :close-on-content-click="false"
                    offset-y
                    v-if="showFilters"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            class="justify-end"
                            icon
                        >
                            <v-icon :color="isAnyFilterActive ? 'primary' : ''">fa-filter</v-icon>
                        </v-btn>
                    </template>
                    <v-card width="322">

                        <v-list>
                            <v-list-item v-for="(filter, key) in switchFilters" :key="key">
                                <v-list-item-action>
                                    <v-switch
                                        v-bind:input-value="selectedSwitch === filter"
                                        v-on:change="selectedSwitch === filter ? selectedSwitch = '' : selectedSwitch = filter"
                                    >
                                        <template v-slot:label>
                                            <span
                                                class="pr-2"
                                                v-text="$t(`filters.${filter}`)"
                                            ></span>
                                        </template>
                                    </v-switch>
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item v-if="showDateFilter">
                                <v-list-item-action>
                                    <v-menu offset-y :close-on-content-click="false" v-model="openDatePicker">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-bind="attrs" v-on="on"
                                                readonly
                                                prepend-icon="fa-calendar-alt"
                                                v-model="dateFilter"
                                                @click="openDatePicker = !openDatePicker"
                                                :label="$t('filters.date_range')"
                                            >
                                                <template v-slot:prepend>
                                                    <v-icon class="ml-1" :color="dateFilter.length ? 'primary' : ''">
                                                        fa-calendar-alt
                                                    </v-icon>
                                                </template>
                                                <template v-slot:append-outer v-if="dateFilter.length">
                                                    <v-icon class="ml-1" @click="dateFilter = []">
                                                        fa-calendar-times
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateFilter"
                                            range
                                            scrollable
                                            no-title
                                        ></v-date-picker>

                                    </v-menu>
                                </v-list-item-action>
                            </v-list-item>


                        </v-list>

<!--                        <v-card-actions>-->
<!--                            <v-spacer></v-spacer>-->

<!--                            <v-btn-->
<!--                                text-->
<!--                                @click="filterMenu = false"-->
<!--                                v-text="$t('cancel')"-->
<!--                            />-->
<!--                            <v-btn-->
<!--                                color="primary"-->
<!--                                text-->
<!--                                v-text="$t('save')"-->
<!--                                @click="saveFilters"-->
<!--                            />-->
<!--                        </v-card-actions>-->
                    </v-card>
                </v-menu>

            </v-col>
        </v-row>

    </v-card>
</template>

<script>

import Store from '@/utils/Store';

export default {
    name: 'stageFilter',
    props: ['process', 'stage', 'stat', 'selected', 'showFilters', 'flat', 'bulkUpdateButton'],
    data() {
        return {
            filterMenu: false,
            openDatePicker: false,
            selectedSwitch: '',
            switchFilters: ['only_completed', 'only_failed', 'only_completed_or_failed'],
            dateFilter: [],
        }
    },
    created() {
        const savedFilters = Store.getUserSetting(`candidates.${this.process}_${this.stage}_filters`);
        if (!savedFilters) return;

        const { only, date } = savedFilters;

        this.selectedSwitch = only || '';
        this.dateFilter = date && date.length === 2 ? date : [];

    },
    computed: {
        showDateFilter() {
            if (!this.selectedSwitch) {
                this.dateFilter = [];
            }
            return !!this.selectedSwitch;
        },
        filters() {
            return {
                only: this.selectedSwitch || undefined,
                date: this.dateFilter || undefined,
            };
        },
        isAnyFilterActive() {
            return !!(this.selectedSwitch || this.dateFilter.length === 2);
        }
    },
    watch: {
        filters() {
            this.$emit('filterUpdate', this.filters, `${this.process}.${this.stage}`);
            Store.setUserSetting(`candidates.${this.process}_${this.stage}_filters`, this.filters);
        }
    }
}
</script>

<style scoped>

</style>