import Axios from 'axios';

const AxiosSymbol = Symbol('axios');
const AxiosCancelSymbol = Symbol('axios_cancel');

const registerFetch = (vue, axios, cancelSource) => {
    if (vue[AxiosSymbol] === undefined)
        vue[AxiosSymbol] = [];

    if (vue[AxiosCancelSymbol] === undefined)
        vue[AxiosCancelSymbol] = [];

    vue[AxiosSymbol].push(axios);
    vue[AxiosCancelSymbol].push(cancelSource);

    axios.finally(() => {
        const index = vue[AxiosSymbol].indexOf(axios);
        vue[AxiosSymbol].splice(index, 1);
        vue[AxiosCancelSymbol].splice(index, 1);
    });

    return axios;
};

const addCancelToken = (data) => {
    const CancelToken = Axios.CancelToken;
    data.cancelToken = CancelToken.source();
    return data.cancelToken;
}

export default {
    methods: {
        axios() {
            const cancelSource = addCancelToken(arguments);
            return registerFetch(this, Axios.apply(this, arguments), cancelSource);
        },

        axiosGet() {
            const cancelSource = addCancelToken(arguments);
            return registerFetch(this, Axios.get.apply(this, arguments), cancelSource);
        },

        axiosPost() {
            const cancelSource = addCancelToken(arguments);
            return registerFetch(this, Axios.post.apply(this, arguments), cancelSource);
        },
        axiosDelete() {
            const cancelSource = addCancelToken(arguments);
            return registerFetch(this, Axios.delete.apply(this, arguments), cancelSource);
        },

        beforeDestroy() {
            if (undefined !== this[AxiosCancelSymbol]) {
                for (const axiosCancel of this[AxiosCancelSymbol])
                    axiosCancel.cancel('Before destroy cancel');
                this[AxiosSymbol].length = 0;
                this[AxiosCancelSymbol].length = 0;
            }
        },
    }
};
