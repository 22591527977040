import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import he from 'vuetify/lib/locale/he';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {he},
        current: 'he',
    },
    rtl: true,
    theme: {
        dark: false,
        default: 'light',
        themes: {
            light: {
                primary: '#2584c6',
                secondary: '#e91e63',
                accent: '#00bcd4',
                error: '#a00a00',
                warning: '#ffc107',
                info: '#868686',
                success: '#009688',
            }
        }
    },
    icons: {
        iconfont: 'fa',
    },
});
