<template>
    <a :href="to"
       class="mt-3 v-btn v-btn--router theme--light button"
       :class="outlined ? 'font-weight-bold v-btn--outlined primary--text' : 'v-btn--is-elevated v-btn--has-bg primary'"
       @click="$emit('click')"
    >
        <span class="button-icon">
            <v-icon small class="pa-1" color="white">{{ icon }}</v-icon>
        </span>
        <span class="v-btn__content">
            <slot/>
        </span>
    </a>
</template>

<script>
export default {
    name: 'quickActionButton',
    props: ['to', 'icon', 'outlined']
}
</script>

<style lang="scss">
.button {
    width: 235px;
    .button-icon {
        background-color: #39484f;
        align-content: flex-start;
        height: 34px;
        width: 40px;
        border-radius: 4px;
        display: flex;
        i {
            margin: auto;
        }
    }
}
</style>