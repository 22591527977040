import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import * as he from './utils/i18n.he';
const Rollbar = require('vue-rollbar');
import Hotjar from 'vue-hotjar'

import VueI18n from 'vue-i18n';

import VueAxiosMixin from './utils/VueAxiosMixin.js';
import VueTimersMixin from './utils/VueTimersMixin.js';

Vue.use(Rollbar, {
  accessToken: 'd9219a228ad648969bc6513c9c01b390',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === 'production',
  source_map_enabled: true,
  environment: 'production',
  payload: {
    client: {
      javascript: {
        code_version: require('../package.json').version
      }
    }
  }
});

Vue.use (Hotjar, {
  id: '2862247', // Hotjar Site ID
  isProduction: process.env.NODE_ENV === 'production',

});

Vue.use(VueI18n);
Vue.mixin(VueAxiosMixin);
Vue.mixin(VueTimersMixin);

const messages = {
  he
}

const i18n = new VueI18n({
  locale: 'he', // set locale
  messages, // set locale messages
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
