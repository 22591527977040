import Vue from 'vue';
import isValidIsraelId from '@/utils/israelIdt';
import axios from 'axios';

const debounce = (func, timeout = 500) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), timeout);
    };
};

let _permissions = [],
    _roles = [],
    _permissionsMap = {},
    _userSettings = {},
    _userSettingsDebouncedUpdates = {};

export default Vue.observable({
    loginData: {
        user_id: null,
    },
    popupsQueue: [],
    setUserPermissions(permissions) {
        _permissions = permissions || [];
        _permissionsMap = {};
        for (let i = 0, len = _permissions.length; i < len; i++) {
            _permissionsMap[_permissions[i]] = true;
        }
    },
    setUserSettings(value) {
        _userSettings = value || {};
    },
    hasPermission(perm) {
        return _permissionsMap[perm] === true;
    },
    hasAnyPermissionIn() {
        let perms = arguments;
        if (perms.length === 1 && perms[0].splice) {
            perms = perms[0];
        }
        for (let i = 0; i < perms.length; i++) {
            if (_permissionsMap[perms[i]] === true) return true;
        }
        return false;
    },
    fieldsRules(vue) {
        return {
            nameRules: [
                v => !!v.trim() || vue.$t('new_candidate.field_required'),
                v => (v && v.length <= 30) || vue.$t('new_candidate.field_too_long'),
            ],
            emailRules: [
                v => !!v.trim() || vue.$t('new_candidate.field_required'),
                v => /.+@.+\..+/.test(v) || vue.$t('new_candidate.invalid_email'),
                v => (v && v.length <= 50) || vue.$t('new_candidate.field_too_long'),
            ],
            phoneRules: [
                v => !!v.trim() || vue.$t('new_candidate.field_required'),
                v => (v && v.length === 10) || vue.$t('new_candidate.invalid_phone'),
            ],
            idtRules: [
                v => !!v.trim() || vue.$t('new_candidate.field_required'),
                v => (v && isValidIsraelId(v)) || vue.$t('new_candidate.invalid_idt'),
            ],
            siteRules: [
                v => !!v || vue.$t('new_candidate.field_required'),
                v => vue.$t('sites').includes(v) || vue.$t('new_candidate.unknown_site')
            ],
            langRules: [
                v => !v || vue.$t('languages').includes(v) || vue.$t('new_candidate.unknown_lang')
            ],
            notEmptyRule: [
                v => !!v && !!v.trim() || vue.$t('new_candidate.field_required'),
            ]
        }
    },
    getUserSetting(key) {
        const conf = _userSettings[key];
        if (conf) {
            return JSON.parse(conf);
        }
    },
    setUserSetting(key, conf) {

        conf = JSON.stringify(conf);
        const oldConf = _userSettings[key];

        if (conf === oldConf) return;

        _userSettings[key] = conf;

        let debounced = _userSettingsDebouncedUpdates[key];
        if (!debounced) {
            debounced = _userSettingsDebouncedUpdates[key] = debounce(() => {
                return axios.post('/api/me/settings/' + encodeURIComponent(key), {'value': _userSettings[key] || ''});
            }, 500);
        }

        // Debounce the settings update
        debounced();

    }
});
