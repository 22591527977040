import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue'
import Candidates from '../pages/Candidates.vue'
import Dashboard from '../pages/Dashboard.vue'
import Candidate from '../pages/Candidate.vue'
import NewCandidate from '../pages/NewCandidate.vue'
import ImportCandidates from '../pages/ImportCandidates.vue'
import BulkUpdate from '@/components/bulkUpdate';
import Users from '../pages/Users.vue'
import Courses from '../pages/Courses.vue'
import Course from '../pages/Course.vue'

import Store from '@/utils/Store';
import Axios from 'axios';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/candidates',
        name: 'Candidates',
        component: Candidates,
    },
    {
        path: '/candidates/import',
        component: ImportCandidates,
        name: 'ImportCandidates',
    },
    {
        path: '/candidates/bulk_update/:process_name(\\w+)',
        component: BulkUpdate,
        name: 'BulkUpdate',
    },
    {
        path: '/candidate/:candidateId(\\d+)',
        name: 'Candidate',
        component: Candidate
    },
    {
        path: '/candidate',
        name: 'NewCandidate',
        component: NewCandidate
    },
    {
        path: '/candidate/:candidateId(\\d+)/edit',
        name: 'EditCandidate',
        component: NewCandidate
    },
    {
        path: '/users',
        name: 'Users',
        component: Users
    },
    {
        path: '/courses',
        name: 'Courses',
        component: Courses
    },
    {
        path: '/course/:courseId(\\d+)',
        name: 'Course',
        component: Course
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {

    if (to.name === 'Login') {
        if (!Store.loginData.user_id) {
            return next();
        }
        return next({ name: 'Dashboard' });
    }

    if (to.name !== 'Login' && Store.loginData.user_id) return next();

    try {

        const {data} = await Axios.get('/api/auth/login');

        Store.loginData = data;
        Store.setUserPermissions(data['permissions']);
        Store.setUserSettings(data['settings']);

        next();

    } catch (err) {

        if (!err.isAxiosError || !err.response || err.response.status !== 403) {
            Store.popupsQueue.push({type: 'req_error', err});
            console.error(err);
        }

        next({ name: 'Login', query: { return_url: to.fullPath } });

    }

});

export default router
