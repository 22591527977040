<template>
    <div class="mt-15">
        <v-row>
            <v-col cols="3" height="350" class="mr-n8">
                <div class="font-weight-bold text-h1"
                     v-text="$t('dashboard.welcome_title')"/>
                <div class="text-h2"
                     v-text="$t('dashboard.welcome_subtitle')"/>
            </v-col>
            <v-spacer/>
            <div class="col-6">

                <v-row class="totals">

                    <div  class="total right up">
                        <v-icon x-large color="blue-grey">fa-user</v-icon>
                        <span>{{ $t('dashboard.count_active') }}</span>
                        <div class="number" :class="{loading: loadingStat}" v-text="counts.count_active"/>

                    </div>

                    <div class="total left up">
                        <v-icon x-large color="blue-grey">fa-clock</v-icon>
                        <span>{{ $t('dashboard.count_course') }}</span>
                        <div class="number" :class="{loading: loadingStat}" v-text="counts.count_course"/>
                    </div>

                    <v-divider/>

                    <v-divider vertical/>

                    <div class="total right down">
                        <v-icon x-large color="blue-grey">fa-chalkboard-teacher</v-icon>
                        <span>{{ $t('dashboard.count_pass_course') }}</span>
                        <div class="number" :class="{loading: loadingStat}" v-text="counts.count_pass_course"/>
                    </div>

                    <div class="font-weight-bold total left down">
                        <v-icon x-large color="blue-grey">fa-scroll</v-icon>
                        <span>{{ $t('dashboard.count_completed') }}</span>
                        <div class="number pink--text" :class="{loading: loadingStat}" v-text="counts.count_completed"/>
                    </div>

                </v-row>

            </div>
            <v-spacer></v-spacer>
            <div class="col-2 pr-0" v-if="!isCbsUser">

                <quick-action-button
                    to="/candidate"
                    icon="fa-plus-circle"
                >
                    {{ $t('dashboard.add_candidate') }}
                </quick-action-button>
                <quick-action-button
                    to="/candidates/import"
                    icon="fa-file-import"
                >
                    {{ $t('dashboard.import_candidates') }}
                </quick-action-button>
                <quick-action-button
                    to="/users?new=1"
                    icon="fa-user-plus"
                >
                    {{ $t('dashboard.add_user') }}
                </quick-action-button>
                <quick-action-button
                    to="/courses?new=1"
                    icon="fa-chalkboard-teacher"
                >
                    {{ $t('dashboard.add_course') }}
                </quick-action-button>

            </div>
        </v-row>

        <v-row class="mt-15">
            <v-col cols="2"
                   v-if="processStat"
                   v-for="(processTitle, processName) in $t('processes_filters')"
                   :key="processName"
            >
                <process-card-filter
                    :process-stat="processStat[processName]"
                    :process-title="processTitle"
                    :process-name="processName"
                    @click="goTo"
                />
                <v-btn v-if="isCbsUser"
                       color="primary"
                       :outlined="processStat[processName].wait_for_cbs_update === 0"
                       block
                       class="font-weight-bold mt-2"
                       v-text="$t('dashboard.cbs_bulk_update')"
                       :to="`/candidates/bulk_update/${processName}`"
                ></v-btn>

            </v-col>
        </v-row>
    </div>
</template>
<script>

import Store from '@/utils/Store';
import ProcessCardFilter from '@/components/processCardFilter';
import QuickActionButton from "@/components/quickActionButton";

export default {
    name: 'Dashboard',
    components: {QuickActionButton, ProcessCardFilter},
    data() {
        return {
            loading: false,
            loadingStat: false,
            counts: {
                count_active: '',
                count_course: '',
                count_pass_course: '',
                count_completed: '',
            },
            processStat: null,
        }
    },
    created() {
        this.getDashboardStat();
    },
    methods: {
        async getDashboardStat() {

            this.loading = true;

            try {

                this.loadingStat = true;

                const {data} = await this.axiosGet('/api/candidates/new_dashboard_stat');
                this.counts = data;

                this.loadingStat = false;

                const {data: processStat} = await this.axiosGet('/api/candidates/progress/stat');
                this.processStat = processStat;

            } catch (err) {

                Store.popupsQueue.push({type: 'req_error', err});

            }

            this.loading = false

        },
        rowClicked(value) {

            this.$router.push({
                name: 'Candidate',
                params: {candidateId: value['id']}
            })

        },
        goTo(processName, filter) {
            this.$router.push({name: 'Candidates', query: {process: processName, filter}})
        }
    },
    computed: {
        isCbsUser() {
            return !!Store.loginData.roles.find(role => role.name === 'cbs');
        }
    },
}
</script>
<style lang="scss">
.theme--light.v-divider {
    border-color: rgba(0,0,0,0.3);
}
.totals {
    border: 20px #FFFFFF solid;
    background-color: #FFFFFF;
    text-align: right;
    font-size: 1.8em;
    position: relative;
    height: 350px;
    width: 635px;

    .total {
        width: 255px;
        height: 115px;
        position: absolute;
        &.right {
            right: 40px;
        }
        &.left {
            left: 15px;
        }
        &.up {
            top: 30px;
        }
        &.down {
            bottom: 10px;
        }
    }

    span {
        position: absolute;
        right: 58px;
    }

    .v-divider {
        position: relative;
        max-width: 100%;
        top: 50%;
        max-height: 0;
        border: solid;
        border-width: thin 0 0;
        transition: inherit;
    }

    .v-divider--vertical {
        align-self: stretch;
        height: inherit;
        min-height: 100%;
        max-height: 100%;
        max-width: 0;
        width: 0;
        vertical-align: text-bottom;
        margin: 0 -1px;
        left: 50%;
        top: unset;
        border-width: 0 thin;
    }

    .number {
        position: absolute;
        font-weight: bold;
        color: #12426b;
        text-align: right;
        font-size: 2em;
        right: 58px;

        &.loading {
            border: 5px solid #f3f3f3;
            animation: spin 1s linear infinite;
            border-top: 5px solid #555;
            border-radius: 50%;
            width: 50px;
            height: 50px;
        }

    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}

</style>
