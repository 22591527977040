<template>
    <div class="pa-5">
        <h2 class="mb-3" v-text="$t('bulk_update.title')"></h2>
        <h3 class="mb-4" v-text="$t('bulk_update.subtitle', {process: processT})"></h3>

        <v-row>
            <v-col cols="6">
                <v-file-input
                    :disabled="!!importing || loading"
                    v-model="csvFile"
                    outlined
                    show-size
                    dense
                    accept=".csv, text/plain"
                    :label="$t('bulk_update.select_file')"
                >

                </v-file-input>

            </v-col>
        </v-row>
        <v-row>
            <v-col class="summary-area">
                <h3 v-text="$t('bulk_update.summary')"></h3>
                <v-row class="pt-5 pb-5 pr-10 pl-10" text-align="center">
                    <v-col cols="8">
                        <v-row>
                            <v-col cols="4" v-for="(text, field, index) in $t('bulk_update.summary_fields')"
                                   :key="index">
                                {{ text }}: <span v-text="importSummary[field]"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4">
                        <v-switch class="ma-0 pa-0"
                                  v-model="errorsOnly"
                                  :label="$t('bulk_update.errors_only')"
                        ></v-switch>
                        <v-switch class="ma-0 pa-0"
                                  v-model="hideErrors"
                                  :label="$t('bulk_update.hide_errors')"
                        ></v-switch>
                    </v-col>
                    <v-col>

                    </v-col>
                </v-row>

            </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                <v-btn
                    :disabled="!readyToImport.length || loading || !!importing"
                    color="primary"
                    class="font-weight-bold"
                    @click="startImport()"
                    v-text="$t('bulk_update.import')"
                />
            </v-col>
            <v-col align="left">
                <v-btn
                    :disabled="!importing"
                    @click="stopImport = true"
                    v-text="$t('bulk_update.cancel_import')"
                />
            </v-col>
        </v-row>
        <v-row v-if="tableData.length || !!loading">
            <v-col>
                <v-data-table
                    :headers="tableHeaders"
                    :items="tableData"
                    :item-class="setRowClass"
                    :loading="loading"
                    :loading-text="$t('bulk_update.analyzing')"
                    :items-per-page="50"
                    :footer-props="{itemsPerPageOptions: [10,50,100,-1]}"
                >
                    <template v-slot:item.errors="{ item }">
                        <v-chip class="ml-1 mb-1" color="error" style="width: 130px; text-align: center"
                                v-for="(err, i) in item.errors"
                                :key="i"
                        >
                            {{ err }}
                        </v-chip>
                    </template>
                    <template v-slot:footer.prepend>
                        <v-btn
                            color="primary" outlined
                            depressed class="ma-2 mr-5 ml-5 font-weight-bold"
                            @click="tableExport(tableHeaders, tableData, 'update_file')"
                        >
                            <v-icon small class="ml-1">fa-file-download</v-icon>
                            {{ $t('candidates.export') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="tableData.length || !!loading">
            <v-col align="center">
                <v-btn
                    :disabled="!readyToImport.length || loading || !!importing"
                    color="primary"
                    class="font-weight-bold"
                    @click="startImport()"
                    v-text="$t('bulk_update.import')"
                />
            </v-col>
            <v-col align="left">
                <v-btn
                    :disabled="!importing"
                    @click="stopImport = true"
                    v-text="$t('bulk_update.cancel_import')"
                />
            </v-col>
        </v-row>

    </div>
</template>

<script>
import * as Papa from 'papaparse';
import Store from '@/utils/Store';
import sanitizeObject from '@/utils/sanitizeObject';
import downloadTextFile from '@/utils/downloadTextFile';
import TableExport from '@/utils/TableExport';

const readTextFile = (file, encoding) => {
    return new Promise((resolve, reject) => {

        const reader = new FileReader();

        reader.onload = resolve;
        reader.onerror = reject;

        reader.readAsText(file, encoding);

    });
}
const extractFirstString = evt =>
    evt.target.result.split(',')[0]
    .trim()
    .replace(/"/g, '')
    .replace(/\t/g, '');

export default {
    name: 'bulkUpdate',
    data() {
        return {
            loading: false,
            importing: false,
            stopImport: false,
            errorsOnly: false,
            hideErrors: false,
            csvFile: null,
            importData: [],
            imported: [],
            parsedFailed: [],
            importFailed: [],
            duplicates: [],
            internalDuplicatesCounter: 0,
            failedAfterSuccess: 0,
            candidates: [],
        }
    },
    watch: {
        async csvFile(newFile) {
            if (!newFile) return;

            this.parsedFailed = [];
            this.importFailed = [];
            this.duplicates = [];
            this.imported = [];
            this.importData = [];
            this.internalDuplicatesCounter = 0;
            this.failedAfterSuccess = 0;

            let firstString = this.$t('bulk_update.file_headers')[this.process];

            let encoding = 'windows-1255';

            const evt = await readTextFile(newFile, encoding);

            if (firstString === extractFirstString(evt)) {
                return this.parseFile(encoding);
            }

            encoding = 'utf-8';

            const evt2 = await readTextFile(newFile, encoding);

            if (firstString === extractFirstString(evt2)) {
                return this.parseFile(encoding);
            }

            Store.popupsQueue.push({type: 'error', err: this.$t('bulk_update.template_match_error'), timeout: 1000 * 6});
            //return this.downloadTemplate();

        },
        errorsOnly(newValue) {
            if (newValue && this.hideErrors) {
                this.hideErrors = false;
            }
        },
        hideErrors(newValue) {
            if (newValue && this.errorsOnly) {
                this.errorsOnly = false;
            }
        },

    },
    methods: {
        tableExport: TableExport,
        parseFile(encoding) {

            Papa.parse(this.csvFile, {
                encoding,
                skipEmptyLines: 'greedy',
                complete: this.parseCompleted,
                header: true,
                transformHeader: (headerName, index) => {
                    return Object.keys(this.$t('bulk_update.fields_map'))[index];
                },
                transform: (value, header) => {
                    if (header === 'idt') {
                        let [idt] = value.match(/\d+/) || [];
                        return idt && idt.replace(/^0+/, '');
                    }

                    if (header === 'result') {
                        value = value.trim();
                        return this.$t('bulk_update.completed_keys').includes(value) ? 1 : 2;
                    }

                    return value;

                }
            });
        },
        parseCompleted(result) {

            result.data.splice(0, 1);

            let errorsCount = result.errors.length;
            let lastErrorIndex = errorsCount - 1;
            let lastRowIndex = result.data.length - 1;
            if (
                errorsCount &&
                result.errors[lastErrorIndex].row === lastRowIndex &&
                result.errors[lastErrorIndex].code === 'TooFewFields'
            ) {
                result.errors.pop();
                result.data.pop();
                errorsCount--;
            }

            this.analyzeData(result.data)
        },
        async analyzeData(updateData) {

            console.log('%c CSV Parsing Result:', 'background: yellow; color: blue;');
            console.table(updateData);

            const rowErrors = this.$t('bulk_update.row_errors');

            let currentCandidates = [];

            if (!this.candidates.length) {
                await this.getCandidates();
            }

            currentCandidates = this.candidates;

            for (let updateRow of updateData) {

                let errors = [];

                // Fields validation
                if (!updateRow['idt']) {
                    errors.push(this.$t('bulk_update.fields_map.idt') + ' - ' + rowErrors['missing_required_field']);
                }
                if (this.process === 'security_docs' && !updateRow['result']) {
                    updateRow['result'] = 1; // We don't have result column, if it's here it means it's ok.
                }
                if (!updateRow['result']) {
                    errors.push(this.$t('bulk_update.fields_map.result') + ' - ' + rowErrors['missing_required_field']);
                }

                let matchCandidate = currentCandidates.find(candidate => candidate.idt === updateRow.idt);

                if (!matchCandidate) {
                    errors.push(this.$t('bulk_update.row_errors.missing_candidate'));
                } else {
                    updateRow.id = matchCandidate['candidate_id'];

                    if (updateRow['result'] > 1 && matchCandidate['result'] === 1) {
                        errors.push(rowErrors['failed_after_success']);
                        this.failedAfterSuccess ++;
                    }

                }

                // Duplicate key on same data TODO: Change by id field
                const duplicateLookup = updateData.filter(candidate => candidate.idt === updateRow.idt);
                if (duplicateLookup.length > 1) {
                    duplicateLookup.shift();
                    duplicateLookup.forEach(dup => {
                        this.internalDuplicatesCounter++;
                        updateData.splice(updateData.indexOf(dup), 1);
                    });
                }

                if (errors.length) {
                    updateRow['errors'] = errors;
                    this.parsedFailed.push(updateRow);
                }

            }

            this.importData = updateData;

        },
        setRowClass(row) {
            if (this.parsedFailed.includes(row)) {
                return 'error-row';
            }
            if (this.imported.includes(row)) {
                return 'imported-row';
            }
            if (this.importing === row.idt) {
                return 'active-import-row';
            }
        },

        async startImport() {

            this.stopImport = false;

            for (const candidate of this.readyToImport) {

                if (this.stopImport) {
                    break;
                }

                this.importing = candidate.idt;

                let shouldBreak = false;

                let sanitizeUpdate = sanitizeObject(candidate, ['result']);

                const candidateId = candidate['id'];

                try {

                    await this.axiosPost(`/api/candidates/progress/${this.process}/cbs_update/${candidateId}`, sanitizeUpdate);

                    this.imported.push(candidate);

                } catch (err) {

                    let errorStrings = this.$t(`bulk_update.row_errors`);
                    let errorDescription = err.isAxiosError && err.response && err.response.data && err.response.data.description;

                    // TODO: Maybe specific error for import?

                    Store.popupsQueue.push({type: 'req_error', err});
                    this.importFailed.push(candidate);
                    console.error(err, errorDescription);

                } finally {
                    this.importing = null;
                }

                if (shouldBreak) break;

            }

            // TODO: Did we finish?
        },
        async getCandidates() {
            try {

                const {data} = await this.axiosGet(`/api/candidates/${this.process}/cbs_update`);
                this.candidates = data;

                return data;

            } catch (err) {
                return Store.popupsQueue.push({type: 'req_error', err});
            } finally {
                this.loading = false;
            }
        }
    },
    computed: {
        process() {
            return this.$route.params.process_name;
        },
        processValid() {
            if (!this.$t('processes')[this.process]) {
                Store.popupsQueue.push({type: 'error', err: 'This process does not exist!'});
            }

            return !!this.$t('processes')[this.process];
        },
        processT() {
            return this.$t(`processes.${this.process}`)
        },
        tableHeaders() {
            const headers = Object.entries(this.$t('bulk_update.table_headers'))
                .map(([value, text]) => ({
                    value, text,
                    sortable: false,
                }));

            headers.find(col => col.value === 'errors').filter = value => {
                if (!this.hideErrors && !this.errorsOnly) return true;
                if (!value && this.errorsOnly) return false;
                return !(value && this.hideErrors);


            };
            return headers;
        },
        tableData() {
            return this.importData.map(row => {
                return row;
            })
        },
        readyToImport() {
            return this.importData.filter(row => {
                if (this.parsedFailed.includes(row)) return;
                if (this.importFailed.includes(row)) return;
                if (this.imported.includes(row)) return;
                return true;
            });
        },
        importSummary() {
            return {
                total_in_file: this.importData.length + this.internalDuplicatesCounter,
                total_to_import: this.readyToImport.length,
                imported: this.imported.length,
                import_errors: this.importFailed.length,
                duplicates: this.duplicates.length,
                rows_error_count: this.parsedFailed.length,
                internal_duplicates: this.internalDuplicatesCounter,
                rows_approved: this.importData.filter(row => row.result === 1).length,
                rows_failed: this.importData.filter(row => row.result === 2).length,
                rows_failed_after_success: this.failedAfterSuccess,
            }
        }
    },
    beforeDestroy() {
        this.stopImport = true;
    }
}
</script>
<style lang="scss">
.error-row {
    background-color: #c8cecf;
}

.imported-row {
    background-color: #E9FFD1FF;
}

.active-import-row {
    background-color: #1D8ACA;
}

</style>
