<template>
    <v-row class="login ma-16">
        <v-col class="logos">
            <img alt="Poll Logo" src="../assets/logo_2022.jpg" class="logo">
            <img alt="CBS Logo" src="../assets/logo_cbs.png" class="logo">
            <img alt="Tikshoov Logo" src="../assets/logo_tik.png" class="logo">
        </v-col>
        <v-col
            align-self="center"
            align="center"
        >
            <div class="mb-16 mt-n10">
                <h2 class="text-h2 mb-5 headers-color">{{ $t('login.app_header') }}</h2>
                <h3 class="text-h3">{{ $t('login.app_subheader') }}</h3>
            </div>

            <v-card
                color="#F5FCFF"
                flat
                height="98%"
                width="348px"
            >
                <v-form
                >
                    <v-text-field
                        v-model="username"
                        :label="$t('login.username')"
                        outlined
                        dense
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :label="$t('login.password')"
                        outlined
                        dense
                        type="password"
                    ></v-text-field>
                    <v-btn
                        :disabled="!enableLogin"
                        @click="postLogin()"
                        color="primary"
                        width="100%"
                        class="font-weight-bold"
                    >{{ $t('login.login') }}
                    </v-btn>
                </v-form>
            </v-card>
        </v-col>

    </v-row>
</template>

<script>

import Store from '@/utils/Store';
import MessagePopup from '@/components/messagePopup';

export default {
    name: 'Login',
    components: {MessagePopup},
    data() {
        return {
            username: '',
            password: '',
            loading: false,
        }
    },
    computed: {
        enableLogin() {
            return !this.loading && this.username && this.password
        },
        returnUrl() {
            return this.$route.query.return_url;
        }
    },
    methods: {
        async postLogin() {

            const {username, password} = this;

            this.loading = true;

            try {

                const {data} = await this.axiosPost('/api/auth/login', {username, password});

                Store.loginData = data;
                Store.setUserPermissions(data['permissions']);
                Store.setUserSettings(data['settings']);

                return this.$router.push(this.returnUrl || '/dashboard');

            } catch (err) {

                if (err.response && err.response.status === 401) {
                    return Store.popupsQueue.push({type: 'error', err: '403-no-match'});
                }

                Store.popupsQueue.push({type: 'req_error', err});

            } finally {

                this.loading = false;

            }
        }
    },
}
</script>

<style lang="scss">
.login {
    background: #F5FCFF;
    box-shadow: 0 4px 19px 6px rgba(172, 224, 245, 0.42);
    border-radius: 14px;

    .logos {
        background: #FFFFFF;
        border-radius: 14px;
        max-width: 305px;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .logo {
            max-width: 180px;
            min-width: 100px;
            height: auto;
        }
    }
}
</style>
