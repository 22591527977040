import * as Papa from 'papaparse';
import downloadTextFile from '@/utils/downloadTextFile';
import * as dayjs from 'dayjs';


const TableExport = (headersLine, data, fileName) => {

    const csvHeadersRow = {};

    headersLine.forEach(({value, text}) => csvHeadersRow[value] = text);

    const csvData = [csvHeadersRow, ...data];
    const csv = '\ufeff' + Papa.unparse(csvData, {header: false});

    downloadTextFile(`${fileName}-${dayjs().format('YYYY_MM_DD-HH_mm_ss')}.csv`, csv);
}

export default TableExport;