<template>
    <v-card height="380" flat color="white">
        <div class="process-card-title">
        <div class="result-icon"  :class="{ completed: isCompleted, failed: isFailed }">
            <v-icon x-large v-show="isCompleted" class="green--text">fa-check-circle</v-icon>
            <v-icon x-large v-show="isFailed" class="red--text">fa-times</v-icon>
        </div>

        <v-card-title v-text="$t('processes')[processName]" class="justify-center font-weight-bold"/>
        </div>
        <v-divider></v-divider>

        <v-list two-line>
            <v-list-item
                v-for="(value, stageName) in $t(`stages.${processName}`)"
                @dblclick="$emit('stageClick', processName, stageName, progress[stageName])"
                :key="stageName"
                :title="$t('candidate.updated_by', {name: progress[stageName].updated_by}) + (progress[stageName].metadata ? '\r' + JSON.stringify(progress[stageName].metadata) : '')"
            >
                <span
                    @dblclick="$emit('postProgress', processName, stageName, {result: progress[stageName].result ? 0 : 2})"
                    @click="$emit('postProgress', processName, stageName, {result: progress[stageName].result ? 0 : 1})"
                    class="dot"
                    :class="getDotColor(stageName)"
                />

                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold " v-text="value"/>
                    <v-list-item-subtitle
                        class="text-button"
                        :class="{'empty-placeholder': !progress[stageName].updated_at || !progress[stageName].result}"
                        v-text="progress[stageName].updated_at && progress[stageName].result ? formatDateTime(progress[stageName].updated_at) : ''"/>
                </v-list-item-content>

            </v-list-item>
        </v-list>

    </v-card>
</template>

<script>
import * as dayjs from 'dayjs';

const COLORS = {
    'completed': 'green',
    'next_stage': 'orange',
    'declined': 'red',
    'info_only': '',
}

export default {
    name: 'processCard',
    props: ['processName', 'progress', 'nextStage'],
    data() {
        return {
            dotColors: ['', 'green', 'red'],

        }
    },
    computed: {
        isCompleted() {
            return this.progress.cbs_update.result === 1;
        },
        isFailed() {
            return Object.values(this.progress).some(stage => stage.result > 1);
        },
    },
    methods: {
        formatDateTime(date) {
            return dayjs(date).format('HH:mm - DD/MM')
        },
        getDotColor(stage) {

            if (this.nextStage === stage) {
                return COLORS.next_stage;
            }
            const {result} = this.progress[stage];

            if (result === 1)
                return COLORS.completed;
            if (result > 1)
                return COLORS.declined;

            return COLORS.info_only;

        },
    }
}
</script>

<style lang="scss">
.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin-top: -20px;
    margin-left: 15px;
    background-color: #939393;
    display: inline-block;
}
.process-card-title {
    background-color: #ebdae4;

    .result-icon {

        position: absolute;
        left: 0;
        right: 0;
        top: -25px;
        margin-left: auto;
        margin-right: auto;
        width: 40px;
        i {
            background-color: #000000;
            border-radius: 100%;
            border-style: solid;
            border-width: 1px;
            border-color: black;
        }
        &.failed {
            i {
                width: 45px;
            }
        }
    }
}
</style>