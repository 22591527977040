<template>
    <v-data-table
        :loading="loading"
        class="pa-5"
        :items="users"
        :headers="tableHeaders"
    >
        <template v-slot:top>
            <v-toolbar
                flat
            >
                <v-toolbar-title class="font-weight-bold">{{ $t('users.title') }}</v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                    v-model="dialog"
                    max-width="500px"
                >
                    <template v-slot:activator="{on, attrs}" v-if="hasPermission('new_user')">
                        <v-btn
                            class="font-weight-bold"
                            v-text="$t('users.new_user')"
                            @click="editItem()"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-text-field
                                            v-model="editedItem.username"
                                            :label="$t('users.user_fields.username')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-text-field
                                            v-model="editedItem.first_name"
                                            :label="$t('users.user_fields.first_name')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-text-field
                                            v-model="editedItem.last_name"
                                            :label="$t('users.user_fields.last_name')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-text-field
                                            v-model="editedItem.work_email"
                                            :label="$t('users.user_fields.work_email')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-text-field
                                            v-model="editedItem.phone"
                                            :label="$t('users.user_fields.phone')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-select
                                            v-model="editedItem.role_id"
                                            :label="$t('users.user_fields.role_id')"
                                            :items="rolesList"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="close"
                            >
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                                {{ $t('save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="">{{ $t('users.approve_delete') }}</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ $t('ok') }}</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.role="{ item }">
            {{ item['role_description'] }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon v-if="hasPermission('edit_user')"
                    @click="editItem(item)"
            >
                fa-edit
            </v-icon>
            <v-icon v-if="hasPermission('delete_user')"
                    class="mr-2"
                    @click="deleteItem(item)"
            >
                fa-trash
            </v-icon>
        </template>
        <template v-slot:footer.prepend>
            <v-alert
                v-if="password"
                v-html="$t('users.password_alert', {password})"
                icon="fa-key"
                color="warning"
            />
        </template>

    </v-data-table>
</template>

<script>
import Store from '@/utils/Store';
import sanitizeObject from '@/utils/sanitizeObject';

export default {
    name: 'Users',
    data() {
        return {
            password: '',
            loading: false,
            users: [],
            roles: [],
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {
                id: '',
                username: '',
                first_name: '',
                last_name: '',
                work_email: '',
                phone: '',
                gender: '',
                comment: '',
                role_id: '',
            },
            defaultItem: {
                id: '',
                username: '',
                first_name: '',
                last_name: '',
                work_email: '',
                phone: '',
                gender: '',
                comment: '',
                role_id: '',
            }
        }
    },
    methods: {
        hasPermission: Store.hasPermission,
        async loadData() {
            try {
                const {data} = await this.axiosGet('/api/users', {params: {fields: '*'}});
                this.users = data;

                const {data: roles} = await this.axiosGet('/api/roles');
                this.roles = roles;

            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }
        },
        async postUser(user) {
            let sanitizedUser = sanitizeObject(user, Object.keys(this.$t('users.user_fields')));
            if (sanitizedUser.role_id) {
                sanitizedUser.roles = [sanitizedUser.role_id];
                delete sanitizedUser.role_id;
            }
            this.loading = true;
            try {
                const {data} = await this.axiosPost('/api/users' + (user.id ? `/${user.id}` : ''), sanitizedUser);
                this.password = data['new_password'];
                this.setTimeout(() => this.password = '', 30 * 1000);
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            } finally {
                this.loading = false;
            }
        },
        async deleteUser(userId) {
            this.loading = true;
            try {
                await this.axiosDelete('/api/users/' + userId);
                Store.popupsQueue.push({type: 'success', message: this.$t('users.deleted')});
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            } finally {
                this.loading = false;
            }
        },

        editItem(item) {
            this.editedIndex = this.users.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.users.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            await this.deleteUser(this.editedItem.id);
            this.users.splice(this.editedIndex, 1)
            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            });
            if (this.$route.query.new) {
                this.$router.replace('/users');
            }
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        async save() {
            await this.postUser(this.editedItem);
            await this.loadData();
            this.close();
        },
    },
    created() {
        this.loadData();
        if (this.$route.query.new) {
            this.editItem();
        }
    },
    computed: {
        tableHeaders() {
            return Object.entries(this.$t('users.table_headers')).map(([value, text]) => ({value, text}));
        },
        formTitle() {
            return this.editedIndex === -1 ? this.$t('users.new_user') : this.$t('users.edit_user')
        },
        rolesList() {
            return this.roles
                .filter(role => role.name !== 'admins')
                .map(({id, description}) => ({value: id, text: description}))
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

}
</script>

<style scoped>

</style>
