var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.loggedIn)?_c('v-app-bar',{attrs:{"dark":"","clipped-right":"","height":"80px","color":"#2f394a","app":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-menu',{staticClass:"col-1",attrs:{"bottom":"","left":"","offset-y":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-bars")])],1)]}}],null,false,88409408)},[_c('v-list',{attrs:{"nav":"","color":"#2f394a"}},_vm._l((_vm.$t('nav_menu')),function(ref){
var route = ref.route;
var label = ref.label;
var icon = ref.icon;
return _c('v-list-item',{key:route,attrs:{"to":route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(label)}})],1)}),1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('img',{staticClass:"logo",class:{pointer: _vm.$route.path !== '/dashboard'},attrs:{"title":_vm.appVersion,"alt":"poll logo","src":require("./assets/logo_2022_inverted.png")},on:{"click":function($event){_vm.$route.path !== '/dashboard' && _vm.$router.push('/dashboard')}}})]),_c('v-col',{attrs:{"cols":"4","offset-xl":"3","offset-lg":"3","offset-md":"3","offset-sm":"1","offset":"0"}},[_c('v-text-field',{ref:"idtSearchField",staticClass:"ma-3 mt-10 change-error-color",attrs:{"dark":"","loading":_vm.loading,"dense":"","clearable":"","outlined":"","flat":"","rules":_vm.idtRules,"label":_vm.$t('app_bar.fast_id_search'),"append-icon":"fa-search"},on:{"click:append":_vm.onIdtSearch,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onIdtSearch.apply(null, arguments)}},model:{value:(_vm.idtSearch),callback:function ($$v) {_vm.idtSearch=$$v},expression:"idtSearch"}})],1),_c('v-col',{attrs:{"align":"end"}},[_c('v-icon',{staticClass:"user-icon"},[_vm._v("fa-user")]),_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.userDisplayName))]),_c('v-btn',{attrs:{"plain":"","rounded":""},on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',{staticClass:"ml-3"},[_vm._v("fa-sign-out-alt")])],1)],1)],1)],1):_vm._e(),_c('v-main',{staticClass:"app-background "},[_c('v-container',{staticClass:"app-width"},[_c('router-view'),_c('message-popup')],1)],1),_c('v-footer',{attrs:{"app":""}},[_c('v-img',{staticClass:"ma-1",attrs:{"contain":"","height":"30px","title":_vm.appVersion,"alt":"CenterPoint Logo","src":require("./assets/logo_centerpoint.png")},on:{"click":function($event){return _vm.openSite()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }