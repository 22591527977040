<template>
    <v-row>
        <v-col class="summary-area mt-5">
            <h2 class="mt-5 mr-5" v-text="$t('course.title')"/>
            <v-row class="pt-5 pb-5 pl-10 mt-5 offset-1" align="center">
                <v-col cols="2" v-for="(text, field, index) in $t('course.basic_fields')" :key="index">
                    <div v-text="text" class="font-weight-bold"></div>
                    <span v-text="courseFields[field]"/>
                </v-col>
            </v-row>
        </v-col>


        <v-col cols="12" class="pa-5">
            <v-data-table
                    v-model="selectedCandidates"
                    :headers="tableHeaders"
                    :items="tableRows"
                    :loading="loading"
                    item-key="id"
                    show-select
                    @click:row="rowClicked"
                    :no-data-text="$t('course.table_no_candidates')"
            >
                <template v-slot:top>
                    <v-toolbar
                            flat
                    >
                        <v-toolbar-title class="font-weight-bold" v-text="$t('course.table_title')"/>


                        <v-spacer></v-spacer>

                        <div v-show="selectedCandidates.length" v-if="hasPermission('edit_candidate')">
                            <v-btn color="primary" depressed class="ma-2 font-weight-bold" outlined
                                   v-text="$t('course.presence_approve')"
                                   @click="progressUpdate('presence_result', 1)"
                            ></v-btn>
                            <v-btn color="primary" depressed class="ma-2 font-weight-bold" outlined
                                   v-text="$t('course.presence_decline')"
                                   @click="progressUpdate('presence_result', 2)"
                            ></v-btn>
                            <v-btn color="primary" depressed class="ma-2 font-weight-bold" outlined
                                   v-text="$t('course.presence_not_finished')"
                                   @click="progressUpdate('presence_result', 3)"
                            ></v-btn>

                            <v-btn color="primary" depressed class="ma-2 font-weight-bold mr-10" outlined
                                   v-text="$t('course.send_reminder')"
                                   @click="alert('Under development... Try later')"
                            ></v-btn>
                            <v-btn color="primary" depressed class="ma-2 font-weight-bold" outlined
                                   v-text="$t('course.reminder_approve')"
                                   @click="progressUpdate('send_reminder', 1)"
                            ></v-btn>
                        </div>
                    </v-toolbar>
                </template>
                <template v-slot:footer.prepend>
                    <v-btn
                        color="primary" outlined
                        depressed class="ma-2 mr-5 ml-5 font-weight-bold"
                        @click="exportCurrentTable()"
                    >
                        <v-icon small class="ml-1">fa-file-download</v-icon>
                        {{ $t('candidates.export') }}
                    </v-btn>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>

<script>
import Store from '@/utils/Store';
import dayjs from 'dayjs';
import TableExport from '@/utils/TableExport';

export default {
    name: 'Course',
    data() {
        return {
            courseData: {
                id: '',
                description: '',
                type: '',
                site: '',
                scheduled_at: '',
                candidates: [],
            },
            loading: false,
            selectedCandidates: [],
        }
    },
    methods: {
        hasPermission: Store.hasPermission,
        async getData() {
            this.loading = true;
            try {
                const {data} = await this.axiosGet(`/api/courses/${this.courseId}`);
                this.courseData = data;
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }
            this.loading = false;
        },
        async progressUpdate(stageName, result) {
            this.loading = true;

            for (let candidate of this.selectedCandidates) {
                try {
                    await this.axiosPost(`/api/candidates/progress/${this.courseData.type}_course/${stageName}/${candidate.id}`, {result});
                } catch (err) {
                    Store.popupsQueue.push({type: 'req_error', err});
                }
            }
            Store.popupsQueue.push({type: 'success', message: this.$t('course.actions_completed')});
            // TODO: Some progress view
            this.loading = false;
        },
        alert(message) {
            Store.popupsQueue.push({type: 'info', message});
        },
        rowClicked(row) {
            this.$router.push({
                name: 'Candidate',
                params: {candidateId: row['id']}
            });
        },
        exportCurrentTable() {

            TableExport(this.tableHeaders, this.tableRows, 'course_candidates');

        },
    },
    created() {
        this.getData();
    },
    computed: {
        courseId() {
            return this.$route.params.courseId;
        },
        courseFields() {
            const data = JSON.parse(JSON.stringify(this.courseData));
            return Object.assign(data, {
                type: this.$t('courses.types')[this.courseData.type],
                scheduled_at: dayjs(this.courseData.scheduled_at).format('HH:mm - DD/MM/YYYY')
            })
        },
        tableHeaders() {
            return Object.entries(this.$t('course.candidates_table'))
                    .map(([value, text]) => ({
                        value, text,
                        sortable: true,
                    }));
        },
        tableRows() {
            return this.courseData.candidates.map(candidate => {
                candidate.email = `tik${candidate.idt}@node.zyx.bz`;
                return candidate;
            });
        }
    }
}
</script>

<style scoped>

</style>