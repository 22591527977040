const TimeoutsSymbol = Symbol('timeouts');
const IntervalsSymbol = Symbol('intervals');

export default {
    methods: {
        /**
         *
         * @param {TimerHandler} handler
         * @param {number} [timeout]
         * @param {any[]} args
         * @returns {number}
         */
        setTimeout(handler, timeout, ...args) {
            if (undefined === this[TimeoutsSymbol])
                this[TimeoutsSymbol] = [];

            const handle = setTimeout(handler, timeout, ...args);
            this[TimeoutsSymbol].push(handle);

            return handle;
        },

        /**
         *
         * @param {TimerHandler} handler
         * @param {number} [timeout]
         * @param {any[]} args
         * @returns {number}
         */
        setInterval(handler, timeout, ...args) {
            if (undefined === this[IntervalsSymbol])
                this[IntervalsSymbol] = [];

            const handle = setInterval(handler, timeout, ...args);
            this[IntervalsSymbol].push(handle);

            return handle;
        },

        /**
         *
         * @param {number} handle
         * @returns {self}
         */
        clearTimeout(handle) {
            if (undefined !== this[TimeoutsSymbol]) {
                const idx = this[TimeoutsSymbol].indexOf(handle);
                if (idx !== -1) {
                    this[TimeoutsSymbol].splice(idx, 1);
                    clearTimeout(handle);
                }
            }

            return this;
        },

        /**
         *
         * @param {number} handle
         * @returns {self}
         */
        clearInterval(handle) {
            if (undefined !== this[IntervalsSymbol]) {
                const idx = this[IntervalsSymbol].indexOf(handle);
                if (idx !== -1) {
                    this[IntervalsSymbol].splice(idx, 1);
                    clearInterval(handle);
                }
            }

            return this;
        },

        beforeDestroy() {
            if (undefined !== this[TimeoutsSymbol]) {
                for (const handle of this[TimeoutsSymbol])
                    clearTimeout(handle);
                this[TimeoutsSymbol].length = 0;
            }

            if (undefined !== this[IntervalsSymbol]) {
                for (const handle of this[IntervalsSymbol])
                    clearInterval(handle);
                this[IntervalsSymbol].length = 0;
            }
        },
    }
};
