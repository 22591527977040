<template>
    <div class="mt-15">
        <v-row class="pa-1 mb-15">
            <v-col cols="8" class="summary-area">
                <v-row class="pr-6 mt-2">
                    <v-col class="text-h4">
                        <v-icon large class="ml-2">fa-user</v-icon>
                        {{ $t('candidate.title') }}
                    </v-col>
                </v-row>
                <v-row class="pr-6 pl-6 mt-3 mb-3">
                    <v-col cols="4" v-for="(text, field, index) in $t('candidate.basic_fields')" :key="index">
                        <div class="font-weight-bold">
                            {{ text }}
                            <span class="field-data"
                                  v-text="candidateDetails[field] || loading && $t('loading') || $t('candidate.missing_info')"/>
                        </div>

                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="2" offset="1" v-if="hasPermission('edit_candidate')">
                <quick-action-button
                    @click="openCourseDialog('online')"
                    icon="fa-plus-circle"
                >
                    {{ $t('candidate.online_course_button') }}
                </quick-action-button>
                <quick-action-button
                    @click="openCourseDialog('front')"
                    icon="fa-user-plus"
                >
                    {{ $t('candidate.front_course_button') }}
                </quick-action-button>
                <quick-action-button
                    @click="campusDialog = true"
                    icon="fa-key"
                >
                    {{ $t('candidate.campus_details.title') }}
                </quick-action-button>


            </v-col>

        </v-row>

        <v-row>
            <v-col
                v-for="(value, processName) in $t('processes')"
                :key="processName"
                v-if="processes[processName]"
                cols="2"
                class="process_card pa-1 mt-10"
            >
                <process-card
                    :process-name="processName"
                    :progress="processes[processName]"
                    v-on:postProgress="postProgress"
                    v-on:stageClick="stageClick"
                    :next-stage="candidateDetails.next_stages[processName]"
                ></process-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="9" class="pr-10 pl-10">
                <h4 class="mb-3">{{ $t('candidate.history') }}</h4>
                <v-timeline dense align-top v-if="historyTable.length">
                    <v-timeline-item small
                                     :key="item.id"
                                     v-for="item in historyTable"
                                     :title="JSON.stringify(item, undefined, 4)"
                    >
                        <v-row class="pt-1">
                            <v-col cols="2">
                                <strong>{{ item.updated_at }}</strong>
                            </v-col>
                            <v-col cols="2">
                                <strong>{{ $t('processes')[item['process_name']] }}</strong>
                                <div>> {{ $t('stages')[item['process_name']][item['stage_name']] }}</div>
                                <div class="text-caption">{{ $t('stages')[item['stage_name']] }}</div>
                                <div class="text-caption"
                                     v-text="item.updated_by && $t('candidate.updated_by', { name: item.updated_by })"
                                />
                            </v-col>
                            <v-col cols="1">
                                <span
                                    class="history-result"
                                    :class="getColor(item.result)"
                                    v-text="$t('candidate.results')[item.result]"
                                />
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </v-col>
            <v-col cols="3" align="end" v-if="hasPermission('edit_candidate')">
                <quick-action-button
                    @click="disableCandidate()"
                    icon="fa-user-minus"
                    v-if="candidateDetails.is_active"
                    :disabled="saving || loading"
                    :outlined="true"
                >
                    {{ $t('candidate.disable') }}
                </quick-action-button>
                <quick-action-button
                    style="z-index: 10; background-color: #FFFFFF"
                    @click="activateCandidate()"
                    icon="fa-user-plus"
                    v-if="!candidateDetails.is_active"
                    :disabled="saving || loading"
                    :outlined="true"
                >
                    {{ $t('candidate.reactivate') }}
                </quick-action-button>

                <quick-action-button
                    style="z-index: 10;"
                    @click="deleteDialog = true"
                    icon="fa-trash"
                    v-if="hasPermission('delete_candidate')"
                    :disabled="saving || loading"
                    :outlined="true"
                >
                    {{ $t('candidate.delete') }}
                </quick-action-button>
            </v-col>
        </v-row>
        <v-dialog
            v-model="campusDialog"
            max-width="500"
        >
            <v-card flat>
                <v-card-title v-text="$t('candidate.campus_details.title')"/>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="campus-details">
                            <div>
                                {{ $t('candidate.campus_details.email') }}
                            </div>
                            <span>{{ campusEmail }}</span>
                        </v-col>
                        <v-col cols="12" class="campus-details">
                            <div>
                                {{ $t('candidate.campus_details.password') }}
                            </div>
                            <span>{{ campusPassword }} </span>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

        </v-dialog>
        <v-dialog v-if="courseDialog" v-model="courseDialog" max-width="700" min-height="700">
            <v-card flat>
                <v-card-title
                    v-text="$t(`candidate.${courseDialog}_course_button`)"
                >

                </v-card-title>
                <v-card-text>
                    <v-select
                        v-model="selectedCourseId"
                        :items="coursesList"
                        :label="$t('candidate.course_select')"
                        :no-data-text="$t('candidate.no_matching_courses')"
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="font-weight-bold"
                           :disabled="!selectedCourseId"
                           color="primary"
                           v-text="$t('save')"
                           @click="saveCourse()"
                    ></v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>
        <v-dialog v-model="deleteDialog">
            <v-card>
                <v-card-title>{{ $t('candidate.delete_confirm') }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="deleteDialog = false">{{ $t('cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ $t('ok') }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="!candidateDetails.is_active" opacity="0.35">
            <div v-text="$t('candidate.is_disabled')"
                 class="red--text font-weight-bold text-h2 is-disabled text-center"></div>
        </v-overlay>
    </div>
</template>

<script>
import * as dayjs from 'dayjs';
import 'dayjs/locale/he';
import MessagePopup from '@/components/messagePopup';
import Store from '@/utils/Store';
import ProcessCard from '@/components/processCard';
import QuickActionButton from "@/components/quickActionButton";

export default {
    name: 'Candidate',
    components: {QuickActionButton, ProcessCard, MessagePopup},
    data() {
        return {
            loading: false,
            loadingCourses: false,
            courses: [],
            saving: false,
            candidateDetails: {
                first_name: '',
                last_name: '',
                idt: '',
                phone: '',
                lang: '',
                site: '',
                is_active: true,
                processes: {},
                next_stages: {},
            },
            history: [],
            selectedCourseId: '',
            campusDialog: false,
            courseDialog: false,
            deleteDialog: false,
        }
    },
    watch: {
        courseDialog(value) {
            if (!value) return;
            this.selectedCourseId = this.assignedCourses[this.courseDialog === 'online' ? 0 : 1];
        }
    },
    computed: {
        historyTable() {
            if (!this.history) return [];

            return this.history.map(row => {

                const {updated_by_first_name, updated_by_last_name, updated_by_username} = row;
                row['updated_by'] = ((updated_by_first_name || '') + ' ' + (updated_by_last_name || '')).trim()
                    || updated_by_username;

                if (row['updated_at']) {
                    row['updated_at'] = dayjs(row['updated_at']).format('DD/MM/YYYY - HH:mm')
                } else {
                    return
                }

                return row;
            }).filter(x => x);
        },
        processes() {
            return this.candidateDetails.processes;
        },
        candidateId() {
            return this.$route.params.candidateId;
        },
        campusEmail() {
            return `tik${this.candidateDetails.idt}@node.zyx.bz`;
        },
        campusPassword() {
            return this.candidateDetails.processes &&
                this.candidateDetails.processes.campus_user &&
                this.candidateDetails.processes.campus_user.user_creation &&
                this.candidateDetails.processes.campus_user.user_creation.metadata &&
                this.candidateDetails.processes.campus_user.user_creation.metadata['campus_password'] ||
            `${this.candidateDetails.idt}`;
        },
        coursesList() {
            return this.courses
                .filter(course =>
                    course.site === this.candidateDetails.site &&
                    course.type === this.courseDialog
                )
                .map(course => ({
                    value: course.id,
                    text: `${course.id} | ${course.description} | ${dayjs(course.scheduled_at).format('HH:mm - DD/MM/YYYY')}`
                }));
        },
        assignedCourses() {
            const result = [
                this.processes['online_course']['schedule_course']['metadata'],
                this.processes['front_course']['schedule_course']['metadata'],
            ];
            return result.map(x => x && x['course_id']);
        },
    },
    methods: {
        hasPermission: Store.hasPermission,
        async activateCandidate() {
            this.saving = true;
            try {
                await this.axiosPost(`/api/candidates/${this.candidateId}`, {is_active: true});
                await this.getData();
                Store.popupsQueue.push({type: 'success', message: this.$t('candidate.reactivated')});
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }
            this.saving = false;
        },
        async disableCandidate() {
            this.saving = true;
            try {
                await this.axiosPost(`/api/candidates/${this.candidateId}`, {is_active: false});
                await this.getData();
                Store.popupsQueue.push({type: 'success', message: this.$t('candidate.disabled')});
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }
            this.saving = false;
        },
        async deleteItemConfirm() {
            this.saving = true;
            try {
                await this.axiosDelete(`/api/candidates/${this.candidateId}`);
                Store.popupsQueue.push({type: 'success', message: this.$t('candidate.deleted')});
                await this.$router.replace('/candidates');
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }
            this.saving = false;
        },
        async postProgress(processName, stageName, body) {

            if (process.env.NODE_ENV === 'production') return;

            this.saving = true;
            try {
                await this.axiosPost(`/api/candidates/progress/${processName}/${stageName}/${this.candidateId}`,
                    body);
                await this.getData();
                Store.popupsQueue.push({type: 'success', message: this.$t('candidate.saved')});
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            }
            this.saving = false;
        },
        async stageClick(processName, stageName, data) {

            if (processName === 'campus_user' && stageName === 'user_creation' && data.result !== 1) {

                if (!Store.hasPermission('recreate_campus')) return Store.popupsQueue.push({message: this.$t('no_permission')});

                this.saving = true;
                try {
                    const {data} = await this.axiosPost(`/api/candidates/${this.candidateId}`, {create_campus_user: true, idt: this.candidateDetails.idt});

                    let message = data['campus_already_exist'] ? this.$t('candidate.campus_user_exist') : this.$t('candidate.campus_user_created');
                    Store.popupsQueue.push({type: 'success', message});
                    return this.getData();
                } catch (err) {
                    Store.popupsQueue.push({type: 'req_error', err});
                } finally {
                    this.saving = false;
                }

            }

        },
        formatDateTime(date) {
            return dayjs(date).format('DD/MM/YYYY - HH:mm')
        },
        async getData() {
            this.loading = true;
            try {
                const {data} = await this.axiosGet(`/api/candidates/${this.candidateId}`);
                this.candidateDetails = data;
                this.history = data.history;
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            } finally {
                this.loading = false;
            }
        },
        async getCourses() {
            this.loadingCourses = true;
            try {
                const {data} = await this.axiosGet(`/api/courses`);
                this.courses = data;
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            } finally {
                this.loadingCourses = false;
            }
        },
        openCourseDialog(type) {
            this.courseDialog = type;
            if (!this.courses.length) {
                this.getCourses();
            }
        },
        async saveCourse() {

            const courses = this.assignedCourses;

            if (courses.includes(this.selectedCourseId)) {
                return Store.popupsQueue.push({type: 'error', err: this.$t('candidate.course_already_assigned')});
            }

            courses[this.courseDialog === 'online' ? 0 : 1] = this.selectedCourseId;

            const postCourses = courses.filter(x => x);

            try {
                await this.axiosPost(`/api/candidates/${this.candidateId}`, {courses: postCourses});
                await this.getData();
            } catch (err) {
                Store.popupsQueue.push({type: 'req_error', err});
            } finally {
                this.courseDialog = false;
            }
        },
        getColor(result) {
            switch (result) {
                case 0:
                    return 'grey lighten-1';
                case 1:
                    return 'green accent-1';
                case 2:
                    return 'deep-orange lighten-3';
            }
        }
    },
    created() {
        this.getData();
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.getData();
    },
}
</script>

<style lang="scss">

.process_card {
    position: relative;

    .v-card__title .v-icon {
        position: absolute;
        margin-top: 1em;
    }

    &:not(:last-child) {
        &::after {
            content: 'ᐸ';
            position: absolute;
            z-index: 1;
            left: 0;
            top: 5%;
            margin: 0 0 0 calc(-2.5em / 2);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5em;
            height: 2.5em;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.60);
            text-align: center;
        }
    }
}

.summary-area {
    background: #cecece;
    border-radius: 5px 5px;
    position: relative;
    border-style: solid;
    border-color: #a4a4a4;
    border-width: 1px;

    .field-title {
        font-weight: bold;
        font-size: 16px;
    }

    .field-data {
        font-weight: normal;
        color: #000000;
    }
}

.campus-details {
    div {
        font-weight: bold;
        min-width: 50px;
    }

    span {
        padding-right: 10px;
    }
}

.empty-placeholder {
    height: 17px;
}

.is-disabled {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    top: 26%;

    width: 600px;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 30px;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 10px 10px 10px 10px;
}

.history-result {
    display: block;
    border-radius: 25% 25%;
    padding: 5px;
    width: 50px;
    text-align: center;
}
</style>
