const isValidIsraelId = idt => {
    idt = idt ? idt + '' : '';
    idt = idt.trim();

    if (idt.length > 9 || idt.length < 2) {
        return false;
    }

    idt = idt.padStart(9, '0');

    return Array
        .from(idt, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
};

export default isValidIsraelId;
